import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, lastValueFrom, map, Observable, tap } from 'rxjs';
import { CTHttpResponse, DataService, DocType, GenerateDocParams, PrintdocResponse, ProcResponseAsObjects } from '../data.service';
import { ContractParsed, DocLink } from '../models/contract';
import { CalcSalaryParams, CalculatedFeesForDay, CheckTimesheetParams, DayOptions, HandleTimesheetsParams, ListNotInvoicedTs, ListNotInvoicedTsParams, ListTimesheetsParams, PrevPeriodInvoices, RegisterTsStatus, RegisterTsStatusResponse, Timesheet, TimesheetBase, TimesheetBaseParsed, TimesheetParsed, TimesheetStatus } from '../models/timesheet';
import { TsCalcInfo, TsCalcInfoParsed } from '../models/ts-calc-info';
import { ContractService } from '../contract/contract.service';
import { UserService } from '../user.service';
import * as moment from 'moment';
import { Department } from '../models/department';
import { DeptService } from '../dept/dept.service';
import { AppService, ButtonStatus } from '../app.service';
import { T7eService } from '../t7e/t7e.service';
import { SendMailParams } from '../models/mail';
import { config } from '../config';
import { ProductionService } from '../prod/production.service';
import { NotificationService } from '../util/notifications/notification.service';
import { ParserService } from '../parser.service';
import { Moment } from 'moment-timezone';


@Injectable({
  providedIn: 'root'
})
export class TimesheetService {
  tsList: TimesheetParsed[] | null = null
  tsList$ = new BehaviorSubject<TimesheetParsed[] | null>(null)
  ownTsList$ = new BehaviorSubject<TimesheetParsed[] | null>(null)
  deptTsList$ = new BehaviorSubject<TimesheetParsed[] | null>(null)
  tsAtProdMgrList$ = new BehaviorSubject<TimesheetParsed[] | null>(null)
  ownTsAtCrew$ = new BehaviorSubject<TimesheetParsed[] | null>(null)
  tsListLoading$ = new BehaviorSubject<boolean>(false)
  isPrevPeriodInvoiced$ = new BehaviorSubject<Map<number,PrevPeriodInvoices>>(new Map())

  depWithPendingTs$ = new BehaviorSubject<Department[]>([]);
  contractsByDeptWithPendingTs$: BehaviorSubject<ContractParsed[]>[] = []
  unapprovedTsByContract$: BehaviorSubject<TimesheetParsed[]>[] = []


  constructor(
    private dataSvc: DataService,
    private contractSvc: ContractService,
    private deptSvc: DeptService,
    private userSvc: UserService,
    private appSvc: AppService,
    private t7e: T7eService,
    private prodSvc: ProductionService,
    private notifSvc: NotificationService,
    private parser: ParserService,
  ) {
    this.getStatusClass = this.getStatusClass.bind(this)
    this.getStatusName = this.getStatusName.bind(this)
    this.canEdit = this.canEdit.bind(this)
    this.canApprove = this.canApprove.bind(this)
    this.canSendBackToCrew = this.canSendBackToCrew.bind(this)
    this.canRetractApproval = this.canRetractApproval.bind(this)
    this.canSetPdfSent = this.canSetPdfSent.bind(this)
    this.canSetInvoiceAccepted = this.canSetInvoiceAccepted.bind(this)
    this.canRemoveTs = this.canRemoveTs.bind(this)
    this.canDelete = this.canDelete.bind(this)
    this.isSameDay = this.isSameDay.bind(this)
    this.isOwnTs = this.isOwnTs.bind(this)
    this.parseTs = this.parseTs.bind(this)
    this.parseSalaryReport = this.parseSalaryReport.bind(this)
    this.parseTsCalcInfo = this.parseTsCalcInfo.bind(this)
    this.getMealPenaltyFee_FirstValuableOtRate = this.getMealPenaltyFee_FirstValuableOtRate.bind(this)
    this.getMealPenaltyRate_setAtProdDeptSf = this.getMealPenaltyRate_setAtProdDeptSf.bind(this)
    this.isApprovedOrHigher = this.isApprovedOrHigher.bind(this)
    this.getTsById = this.getTsById.bind(this)

    this.subscribeLists()
  }

  subscribeLists() {
    this.tsList$
      .pipe(map(tsList => tsList
        ?.filter(ts => ts.tsstatus == TimesheetStatus.atProdMgr || ts.tsstatus == TimesheetStatus.deptHeadApproved)
        || []
      )).subscribe(this.tsAtProdMgrList$)

    this.ownTsList$
      .pipe(map(tsList => tsList
        ?.filter(ts => ts.tsstatus == TimesheetStatus.atCrewMember)
        || []
      )).subscribe(this.ownTsAtCrew$)

    this.tsList$.pipe(
      map(tsList => {
        const deptContracts = this.contractSvc.deptContracts$.value
        return tsList?.filter(ts => deptContracts?.find(c => c.conid == ts.conid))
          || []
      })
    ).subscribe(this.deptTsList$)

    // TODO a depname-t lecserélni depid-re
    // get distinct departments with TimesheetStatus.atProdMgr
    this.tsAtProdMgrList$
      .pipe(map(tsList => tsList
        ?.map(ts => ts.depname)
        ?.filter((v, i, a) => a.indexOf(v) === i)
        ?.map(dn => this.deptSvc.departments$.value?.find(d => d.depname == dn) || [{ depname: 'n/a', depid: 0 }] as Department) || []
      ))
      .subscribe(this.depWithPendingTs$)

    this.depWithPendingTs$.subscribe(deps => {
      deps.forEach(dep => {
        this.tsAtProdMgrList$.pipe(
          map(tsList => {
            const unknownContract = { conid: 0, usname: 'none' } as ContractParsed;
            return tsList
              // TODO filter by depid instead of depname
              ?.filter(ts => ts.depname == dep.depname)
              ?.map(ts => this.contractSvc.allContracts$.value?.find(c => c.conid == ts.conid) || unknownContract)
              ?.filter((v, i, a) => a.indexOf(v) === i)
              || [unknownContract]
          })
        ).subscribe(c => {
          if (!this.contractsByDeptWithPendingTs$[dep.depid!]) {
            this.contractsByDeptWithPendingTs$[dep.depid!] = new BehaviorSubject<ContractParsed[]>(c)
          } else {
            this.contractsByDeptWithPendingTs$[dep.depid!].next(c)
          }
          this.contractsByDeptWithPendingTs$[dep.depid!].subscribe(contracts => {
            contracts.forEach(c => {
              const tsList = (this.tsAtProdMgrList$.value
                ?.filter(ts => ts.conid == c.conid) || [])
                ?.sort((a, b) => a.dStartDate! < b.dStartDate! ? -1 : 1)
                
              if (!this.unapprovedTsByContract$[c.conid!]) {
                this.unapprovedTsByContract$[c.conid!] = new BehaviorSubject<TimesheetParsed[]>(tsList)
              } else {
                this.unapprovedTsByContract$[c.conid!].next(tsList)
              }
            })
          })
        })
      })
    })

    this.tsList$
      .pipe(map(tsList => tsList === null 
          ? null
          : (tsList?.filter(ts => this.isOwnTs(ts) && (ts.tsstatus === null || ts.tsstatus! !== 0)) || [])
      ),
         //tap(tsList => { console.log('ownTsList', tsList) })
      )
      .subscribe(this.ownTsList$)

  }

  listTs(params: ListTimesheetsParams): Observable<TimesheetParsed[] | null> {
    this.tsListLoading$.next(true)
    const retVal = this.dataSvc.listTimesheets(params)
      .pipe(
        // tap(timesheets => {
        //   console.log('Betöltött TS-ek', timesheets)
        // }),
        map(data => data
          ?.map(this.parseTs)
          ?.sort((a, b) => (b.tsid || -1) - (a.tsid || 1))
          || []
        ),
      )
    retVal.subscribe({
      next: timesheets => {
        timesheets = timesheets || []
        if (!this.tsList?.length) this.tsList = timesheets
        else this.tsList = this.replaceTsItems(timesheets, this.tsList)
        this.tsList$.next(this.tsList)
        
        this.tsListLoading$.next(false)
      },
      error: err => {
        console.error(err)
        this.tsListLoading$.next(false)
      }
    })
    return retVal
  }

  handleTs(params: HandleTimesheetsParams): Observable<Timesheet[] | null> {
    this.tsListLoading$.next(true)
    const retVal = this.dataSvc.handleTimesheets(params)
      .pipe(
        tap(timesheets => {
          console.log('Mentett TS', timesheets)
        }),
        map(data => data
          ?.map(this.parseTs)
          || []
        ),
      )

    retVal.subscribe({
      next: timesheetsUpdated => {
        this.tsList = this.replaceTsItems(timesheetsUpdated)
        this.tsList$.next(this.tsList)
        this.tsListLoading$.next(false)
        if (params._tsstatus == TimesheetStatus.atCrewMember) {
          this.sendTsSentBackEmail([{tsId: params._tsid!, saveComment: params._savecomment}])
        }
      },
      error: err => {
        this.tsListLoading$.next(false)
      }
    })
    return retVal
  }

  handleMultipleTs(params: HandleTimesheetsParams[]): Observable<Timesheet[] | null> {
    this.tsListLoading$.next(true)
    const retVal = this.dataSvc.handleMultipleTs(params)
      .pipe(
        tap(timesheets => {
          console.log('Mentett TS', timesheets)
        }),
        map(data => data
          ?.map(this.parseTs)
          || []
        ),
      )
    retVal.subscribe({
      next: timesheetsUpdated => {
        this.tsList = this.replaceTsItems(timesheetsUpdated)
        this.tsList$.next(this.tsList)
        this.tsListLoading$.next(false)
        const aNeedsMail = params
          .filter(p => p._tsstatus == TimesheetStatus.atCrewMember)
          .map(p => ({ tsId: p._tsid!, saveComment: p._savecomment }))
        this.sendTsSentBackEmail(aNeedsMail)
      },
      error: err => {
        console.error(err)
        this.tsListLoading$.next(false)
      }
    })
    return retVal
  }

  registerTsStatus(params: RegisterTsStatus): Observable<RegisterTsStatusResponse> {
    this.tsListLoading$.next(true)
    const retVal = this.dataSvc.registerTsStatus(params)
    retVal
      .pipe(tap(_ => { this.tsListLoading$.next(false) }))
      .subscribe({
        next: registerTsStatusResponse => {
          console.log('registerTsStatusResponse: ', registerTsStatusResponse)
          const tsIds = JSON.parse(params._tsids || '[]') as number[]
          const updatedTsList = this.tsList
            ?.filter(ts => tsIds.includes(ts.tsid!))
            ?.map(ts => ({
              ...ts,
              tsstatus: params._tsstatus,
            })) || null
          this.tsList = this.replaceTsItems(updatedTsList)
          this.tsList$.next(this.tsList)
        },
        error: err => {
          console.error(err)
        }
      })
    return retVal
  }

  sendTsSentBackEmail(params: { tsId: number, saveComment?: string | null }[]): void {
    const sentUserIds: number[] = []
    const mailObservables = params
      .map(param => {
        if (sentUserIds.includes(param.tsId)) return null
        const ts = this.getTsById(param.tsId)
        ts && sentUserIds.push(ts.usid!)
        const sendMailPars = this.getMailParamsForSendBackToCrew(param.tsId, param.saveComment || null)
        return sendMailPars
      })
      .filter(x => !!x)
      .map(mp => this.dataSvc.sendMail(mp!))

    forkJoin(mailObservables).subscribe({
      next: (response) => {
        console.log('email sent', response)
        this.notifSvc.addObservableNotif({ msg: 'A stábtagnak emailt küldtünk arról, hogy visszakapta a timesheetet javításra.', class: 'success', duration: 3000 })
      },
      error: (err) => {
        console.error(err)
        this.notifSvc.addObservableNotif({ msg: 'Nem sikerült a stábtagnak emailt küldeni arról, hogy visszakapta a timesheetet javításra.', class: 'danger' })
      }
    })
  }

  listNotInvoicedTs(startDate: Moment, contractTypes: string[] | undefined, userIds: number[]): Observable<ListNotInvoicedTs[]> {
    const loadingValues = new Map<number, PrevPeriodInvoices>()
    userIds.forEach(usid => {
      const newVal: PrevPeriodInvoices = {
      statusClass: 'loading',
      statusName: 'loading',
      timesheets: null,
      }
      loadingValues.set(usid, newVal)
    })
    this.isPrevPeriodInvoiced$.next(loadingValues)

    const retVal = this.dataSvc.listNotInvoicedTs(startDate, contractTypes)
    retVal.subscribe({
      next: response => {
        const respUserIds = response.map(x => this.contractSvc.getContractById(x.conid)?.usid!)
        console.log('listNotInvoicedTs', response)
        console.log('userIds', userIds)
        const newVal = new Map<number, PrevPeriodInvoices>()
        userIds.forEach(usid => {
          const isNotInvoiced = respUserIds.includes(usid)
          newVal.set(usid, {
            statusName: isNotInvoiced ? 'Elmaradt számla' : 'Nincs elmaradt számla',
            statusClass: isNotInvoiced ? 'warning' : 'success',
            timesheets: response.filter(ts => ts.usid === usid)
          })
        })
        this.isPrevPeriodInvoiced$.next(newVal)
      },
      error: reason => {
        const newVal = new Map<number, PrevPeriodInvoices>()
        userIds.forEach(usid => {
          newVal.set(usid, {
            statusName: 'Betöltés hiba',
            statusClass: 'danger',
            timesheets: null
          })
        })
        this.isPrevPeriodInvoiced$.next(newVal)
      }
    })
    return retVal
  }

  replaceTsItems(timesheetsUpdated: TimesheetParsed[] | null, timesheetsOrig?: TimesheetParsed[] | null): TimesheetParsed[] {
    if (!timesheetsOrig) timesheetsOrig = this.tsList
    timesheetsUpdated?.forEach(tsUpdated => {
      if (!timesheetsOrig) timesheetsOrig = []
      const origIndex = timesheetsOrig?.findIndex(tsOrig => tsOrig.tsid == tsUpdated.tsid)
      if (origIndex < 0) {
        timesheetsOrig.push(tsUpdated)
      } else {
        timesheetsOrig = [
          ...timesheetsOrig.slice(0, origIndex),
          tsUpdated,
          ...timesheetsOrig.slice(origIndex + 1),
        ]
      }

    })

    return timesheetsOrig || []
  }

  getSaveNewStatusParams(ts: Timesheet, status: number, savecomment: string | null = null): HandleTimesheetsParams {
    const allFields = (status == TimesheetStatus.approved) ? this.getAllFieldsForApproval(ts) : undefined
    const params: HandleTimesheetsParams = {
      ...allFields,
      _tsid: ts.tsid,
      _tsstatus: status,
      _savecomment: savecomment,
    }
    return params
  }
  
  /**
   * TS státusz módosításkor el kell menteni a kalkulált értékeket. 
   * Kiszedi a 'calc_salary', 'calc_overtimefee', 'calc_tarate', 'calc_othours', 'calc_tahours' mezők értékét, és sql paraméterként adja vissza
   * A 'calc_salary' értéke a szerkesztőben felülírható, ezért ennél az egy mezőnél a szerkesztőben megadott értéket kell elmenteni, ha van érték megadva
   * @param ts A timesheet, aminek a státuszát módosítani kell
   * @returns 
   */
  private getAllFieldsForApproval(ts: Timesheet): HandleTimesheetsParams {
    const retVal: HandleTimesheetsParams = {}
    //const details: { [key: string]: any } = {}
    const neededFields = [
      //'startdate', 'enddate', 'distandpark', 'vignette', 'dayoptions', 'otherfee',
      // EZEKET JÓVÁHAGYÁSKOR MENTENI KELL! Azért, mert a kalkuláció bemenetei időközben változhattak
      // Ha elmentem őket, akkor a képernyőn megjelenő (gyártásvezető által jóváhagyott) értékek véglegesek lesznek
      'calc_salary', 'calc_overtimefee', 'calc_tafee', 'calc_othours', 'calc_tahours',
    ]
    Object.keys(ts).forEach(key => {
      if (key.startsWith('f_')) {
        //details[key.substring(2)] = ts[key]
      }
      else if (neededFields.includes(key)) {
        console.assert(key.substring(0, 5) === 'calc_', 'Csak calc_ -kal kezdődő mezőt kéne így menteni')
        const saved = ts[key.substring(5)] as number | undefined
        const calc = ts[key] as number | undefined
        const val = (key === 'calc_salary' && this.appSvc.isNumber(saved))
          ? saved
          : (calc || 0)
        
        retVal['_' + key.substring(5)] = val
      }
    })
    const mealPenalty = this.getMealPenaltyFee_FirstValuableOtRate(ts)
    if (mealPenalty) retVal._overtimefee = Number(retVal._overtimefee) + mealPenalty
    //retVal._data = JSON.stringify(details)
    return retVal;
  }

  getMealPenaltyFee_FirstValuableOtRate(ts: Timesheet): number {
    if (!config.HU.TS.MEAL_PENALTY) return 0
    if (!ts.dayoptions?.includes(DayOptions.MealPenalty)) return 0
    const contract = this.contractSvc.getContractById(ts.conid)
    return this.contractSvc.getFirstValuableOtRateByContract(contract)
  }

  getMealPenaltyRate_setAtProdDeptSf(ts: Timesheet): number {
    if (config.HU.TS.MEAL_PENALTY !== 'setAtProdDeptSf') return 0
    const contract = this.contractSvc.getContractById(ts.conid)
    return this.contractSvc.getMealPenaltyRate_setAtProdDeptSf(contract) || 0
  }

  iskmParkVignSuspicious(dataItem: TimesheetParsed): boolean {
    const kmLimit = 500 // HUF
    const parkingLimit = 100 // HUF
    const vignetteLimit = 500 // HUF
    switch (dataItem.currency?.toLowerCase()) {
      case 'huf':
      case undefined:
        if (dataItem.distandpark && dataItem.distandpark < kmLimit) return true
        if (dataItem.park && dataItem.park < parkingLimit) return true
        if (dataItem.vignette && dataItem.vignette < vignetteLimit) return true
        break
      case 'eur':
      case 'usd':
      case 'gbp':
        if (dataItem.distandpark && dataItem.distandpark > kmLimit) return true
        if (dataItem.park && dataItem.park > parkingLimit) return true
        if (dataItem.vignette && dataItem.vignette > vignetteLimit) return true
        break
      default:
        console.error('Ismeretlen pénznem: ' + dataItem.currency)
    }
    return false
  }

  getTsById(tsid: number | null): TimesheetParsed | null {
    return this.tsList$.value?.find(ts => ts.tsid == tsid) || null
  }

  getTsForDate(date: Date, includeEndOfDay: boolean = false, userId?: number): TimesheetParsed[] {
    const retVal = this.tsList
      ?.filter(ts => {
        return this.isSameDay(ts, date, includeEndOfDay)
      })
      ?.filter(ts => userId ? ts.usid == userId : true)
      || []
    return retVal
  }
  getTsForDates(date: Date[], userId?: number | null, includeDeleted: boolean = false, includeDisabled: boolean = false): TimesheetParsed[] {
    // call getTsForDate for each date in date[], and return the union of the results
    const retVal: TimesheetParsed[] = []
    date.forEach(d => {
      retVal.push(...this.getTsForDate(d, false, userId || undefined))
    })
    if (includeDeleted && includeDisabled) return retVal
    else {
      if (!includeDeleted && !includeDisabled) return retVal.filter(ts => ts.tsstatus! > 0)
      if (!includeDeleted && includeDisabled) return retVal.filter(ts => ts.tsstatus! > 0 || ts.tsstatus === TimesheetStatus.disabled)
      if (includeDeleted && !includeDisabled) return retVal.filter(ts => ts.tsstatus! > 0 || ts.tsstatus == TimesheetStatus.deleted)
      return retVal
    }
  }

  isSameDay(ts: TimesheetParsed, date: Date, includeEndOfDay: boolean = false): boolean {
    //console.count('isSameDay')
    return moment(date).isSame(ts.dStartDate, 'day') || (includeEndOfDay && moment(date).isBetween(ts.dStartDate, ts.dEndDate, 'day', '[]'))
  }

  checkTs(params: CheckTimesheetParams) {
    const retVal = this.dataSvc.checkTimesheet(params)
    retVal.subscribe({
      next: ts => {

      },
      error: err => {

      }
    })
    return retVal
  }

  async getCalculatedFeesForDay(params: CalcSalaryParams): Promise<CalculatedFeesForDay> {
    const calc = await lastValueFrom(this.dataSvc.getCalculatedFeesForDay(params))
    return calc
  }

  /** Ha kell, utazónapra és pihenőnapra dupla Per Diemet számol */
  getPerdiemAmount(ts: TimesheetParsed) {
    const contract = this.contractSvc.getContractById(ts.conid)
    const perdiemAmount = contract?.f_sleepoverrate || 0
    // ha nem kell dolgozni, nem adnak neki enni, ezért dupla perdiem jár
    if ((ts.dayoptions?.includes(DayOptions.TravelDay) || ts.dayoptions?.includes(DayOptions.RestDay))) {
      return perdiemAmount * config.PERDIEM_MULTIPLIER_FOR_REST_DAY
    }
    return perdiemAmount
  }
  
  parseTs(ts: Timesheet): TimesheetParsed {
    const user = this.userSvc.allUsers$.value?.find(u => u.usid == ts.usid)
    const retVal: TimesheetParsed = {
      ...ts,
      dStartDate: ts?.startdate ? new Date(ts.startdate) : null,
      dEndDate: ts?.enddate ? new Date(ts.enddate) : null,
      dDate: ts?.startdate ? new Date(new Date(ts.startdate).setHours(0, 0, 0, 0)) : null,
      arrDoclink: this.parseArrDocLink(ts.doclink),
      dayoptions: ts?.dayoptions ? ts.dayoptions : [],

      usname: user?.usname || ts.usname,
      famname: user?.famname || '',
      usnameOrig: user?.usnameOrig || '',
    }
    // az f_ -vel kezdődő mezőket f_ nélkül is beleteszem
    Object.keys(retVal).forEach(key => {
      if (!key.startsWith('f_')) return
      retVal[key.substring(2)] = retVal[key]
    })
    return retVal
  }

  private parseArrDocLink(sDoclink?: string | null): DocLink[] | null {
    if (!sDoclink || sDoclink.toLowerCase().trim() === 'null') return null
    let retVal = JSON.parse(sDoclink) as DocLink[]
    retVal = retVal.map(doc => {
      let pdfid = null as string | null
      if (doc.pdfid) {
        if (!doc.pdfid) {
          // pdfid is null already
        } else if (Array.isArray(doc.pdfid)) {
          const aPdfId = doc.pdfid.filter(x => !!x)
          console.assert(aPdfId.length < 2, 'Egy doclink-ben nem csak egy pdf lehet?. ' + sDoclink)
          pdfid = aPdfId[0]
        } else if (typeof doc.pdfid === 'string') {
          pdfid = doc.pdfid
        } else {
          console.assert(false, 'doclink pdfid nem null, nem string és nem string[]: ' + sDoclink)
        }
      }
      return {
        ...doc,
        pdfid,
      }
    })
    return retVal
  }

  parseSalaryReport(data: TimesheetBase[] | null): Partial<TimesheetBaseParsed>[] {
    if (!data) return []
    // sum by day and usid
    const parsedList = data
    const sum: { [key: string]: Partial<TimesheetBaseParsed> } = {}
    for (let i = 0; i < parsedList.length; i++) {
      const ts = parsedList[i]
      const key = `${ts.usid}-${new Date(ts.startdate!)?.toISOString().substr(0, 10)}`
      const fullName = this.userSvc.getFullName(ts)
      if (!sum[key]) {
        sum[key] = {
          dDate: new Date(ts.startdate!),
          usid: ts.usid!,
          usname: fullName || "Törölt felhasználó",
          email: ts.email || "n/a",
          depid: ts.depid || 0,
          depname: ts.depname || "n/a",
          role: ts.role || "n/a",
          currency: this.contractSvc.getCurrencyByContractId(ts.conid!) || "n/a",
          salary: 0,
          overtimefee: 0,
          tafee: 0,
          perdiem: 0,
          mealpenalty: 0,
          mealpenaltyhours: 0,
          otherfee: 0,
          vignette: 0,
          gas: 0,
          park: 0,
          dailyrentalrate1name: '', dailyrentalrate1: 0,
          dailyrentalrate2name: '', dailyrentalrate2: 0,
          dailyrentalrate3name: '', dailyrentalrate3: 0,
          dailyrentalrate4name: '', dailyrentalrate4: 0,
          dailyrentalrate5name: '', dailyrentalrate5: 0,
          dailyrentalrate6name: '', dailyrentalrate6: 0,
          monthlyrentalrate1name: '', monthlyrentalrate1: 0,
          monthlyrentalrate2name: '', monthlyrentalrate2: 0,
          monthlyrentalrate3name: '', monthlyrentalrate3: 0,
        }
      }
      sum[key].salary! += ts.salary || 0
      sum[key].overtimefee! += ts.overtimefee || 0
      sum[key].tafee! += ts.tafee || 0
      sum[key].perdiem! += ts.perdiem || 0
      sum[key].mealpenalty! += ts.mealpenalty || 0
      sum[key].mealpenaltyhours! += ts.mealpenaltyhours || 0
      sum[key].otherfee! += ts.otherfee || 0
      sum[key].vignette! += ts.vignette || 0
      sum[key].gas! += ts.gas || 0
      sum[key].park! += ts.park || 0
      sum[key].dailyrentalrate1! += ts.dailyrentalrate1 || 0
      sum[key].dailyrentalrate2! += ts.dailyrentalrate2 || 0
      sum[key].dailyrentalrate3! += ts.dailyrentalrate3 || 0
      sum[key].dailyrentalrate4! += ts.dailyrentalrate4 || 0
      sum[key].dailyrentalrate5! += ts.dailyrentalrate5 || 0
      sum[key].dailyrentalrate6! += ts.dailyrentalrate6 || 0
      sum[key].monthlyrentalrate1! += ts.monthlyrentalrate1 || 0
      sum[key].monthlyrentalrate2! += ts.monthlyrentalrate2 || 0
      sum[key].monthlyrentalrate3! += ts.monthlyrentalrate3 || 0
    }
    return Object.values(sum)
  }


  getStatusName(status: number | null | undefined): string {
    switch (status) {
      case null:
      case undefined:
        return this.t7e.lang === 'hu' ? 'Nincs beküldve' : 'Not submitted'
      case TimesheetStatus.disabled:
        return this.t7e.lang === 'hu' ? 'Letiltva' : 'Disabled'
      case TimesheetStatus.deleted:
        return this.t7e.lang === 'hu' ? 'Törölve' : 'Deleted'
      case TimesheetStatus.atCrewMember:
        return this.t7e.lang === 'hu' ? 'Beküldésre vár' : 'Waiting for submission'
      case TimesheetStatus.atDeptHead:
        return this.t7e.lang === 'hu' ? 'Részlegevezetői jóváhagyásra vár' : 'Waiting for department head approval'
      case TimesheetStatus.deptHeadApproved:
        return this.t7e.lang === 'hu' ? 'Részlegvezető jóváhagyta' : 'Approved by department head'
      case TimesheetStatus.atProdMgr:
        return this.t7e.lang === 'hu' ? 'Gyv jóváhagyására vár' : "Waiting for Prod Mgr's approval"
      case TimesheetStatus.approved:
        return this.t7e.lang === 'hu' ? 'Gyv jóváhagyta' : 'Prod Mgr Approved'
      case TimesheetStatus.attGenerating:
        return this.t7e.lang === 'hu' ? 'Számlamelléklet generálása folyamatban' : 'Generating invoice attachment'
      case TimesheetStatus.attSent:
        return this.t7e.lang === 'hu' ? 'Számlamelléklet kiküldve' : 'Invoice attachment sent'
      case TimesheetStatus.invAccepted:
        return this.t7e.lang === 'hu' ? 'Számla befogadva' : 'Invoice accepted'
      default: 
        return this.t7e.lang === 'hu' ? 'Ismeretlen státusz' : 'Unknown status'
    }
  }
  getStatusNames(ts: Timesheet[]): string {
    return ts.map(ts => this.getStatusName(ts.tsstatus)).join(', ')
  }

  getStatusClass(ts?: TimesheetParsed | null | TimesheetParsed[]): string {
    if (!Array.isArray(ts)) ts = [ts]
    let retVal = 'ts-status ts-status-'
    let tmp = '';
    return (ts as TimesheetParsed[]).reduce((prev, curr) => {
      if (prev.includes('waiting') || prev.includes('warning') || prev.includes('unknown') || prev.includes('notsubmitted')) {
        return prev
      }
      switch (curr?.tsstatus) {
        case null:
        case undefined:
          tmp = retVal + 'notsubmitted'
          break;
        case TimesheetStatus.disabled:
          tmp = retVal + 'disabled disabled'
          break;
        case TimesheetStatus.deleted:
          tmp = retVal + 'deleted disabled'
          break;
        case TimesheetStatus.atCrewMember:
          if (this.isOwnTs(ts)) tmp = retVal + 'atcrewmember warning'
          else tmp = retVal + 'atcrewmember disabled'
          break;
        case TimesheetStatus.atDeptHead:
          if (this.userSvc.isDeptHead) tmp = retVal + 'atdepthead warning'
          else if (this.userSvc.isMoreThanDeptHead) tmp = retVal + 'atdepthead'
          else tmp = retVal + 'atdepthead success'
          break;
        case TimesheetStatus.deptHeadApproved:
        case TimesheetStatus.atProdMgr:
          if (this.userSvc.isProdMgr) tmp = retVal + 'atprodmgr warning'
          else if (this.userSvc.isMoreThanDeptHead) tmp = retVal + 'atprodmgr waiting'
          else tmp = retVal + 'atprodmgr success'
          break;
        case TimesheetStatus.approved:
          retVal += 'approved '
          if (this.userSvc.isProdMgr) tmp = retVal + ' success'
          else if (this.userSvc.isFinance) tmp = retVal + ' warning'
          else if (this.isOwnTs(ts)) tmp = retVal + ' success'
          else tmp = retVal + ' waiting'
          break;
        case TimesheetStatus.attGenerating:
          retVal += 'attgenerating '
          if (this.userSvc.isProdMgr) tmp = retVal + ' success'
          else if (this.userSvc.isFinance) tmp = retVal + ' waiting'
          else if (this.isOwnTs(ts)) tmp = retVal + ' success'
          else tmp = retVal + ' waiting'
          break;
        case TimesheetStatus.attSent:
          tmp = retVal + 'attsent success'
          break;
        case TimesheetStatus.invAccepted:
          tmp = retVal + 'invaccepted success'
          break;
        default:
          tmp = retVal + 'disabled unknown'
      }
      return tmp
      }, '')
  }

  getRowClass(event: { dataItem: any, index: number }): string {
    if (event.dataItem?.tsstatus === 0) return 'deleted-row'
    return ''
  }

  getTimesheetStatuses() {
    return Object.keys(TimesheetStatus)
      .map((key: any) => ({
        id: TimesheetStatus[key] as unknown as number,
        name: key as string,
      }
      ))
      .filter(obj => isNaN(obj.name as unknown as number))
  }

  getMailParamsForSendBackToCrew(tsId: number, saveComment: string | null): SendMailParams | null {
    const ts = this.getTsById(tsId)
    console.assert(ts, 'getMailParamsForSendBackToCrew: ts is null')
    if (!ts) return null
    const mailParams = JSON.parse(JSON.stringify(config.HU.TS.SEND_MAIL_FOR_SENDBACK_TO_CREW)) as SendMailParams & { bodyEnding?: string }
    mailParams.sendTo = this.userSvc.getUserById(ts.usid)?.email || ''
    mailParams.body = mailParams.body.replace('{USERNAME}', 'Stábtag')
    if (saveComment?.trim()) mailParams.body += `<br><br><b>Megjegyzés:</b><br>${saveComment.replace(/\n/g, '<br>')}`
    mailParams.body += mailParams.bodyEnding
    delete mailParams.bodyEnding
    return mailParams
  }

  parseTsCalcInfo(info: TsCalcInfo): TsCalcInfoParsed {
    const otRate = info.otrate ? this.parser.dbArrayToArray(JSON.parse(info.otrate)) : []
    const retVal: TsCalcInfoParsed = {
      ...info,
      dStartdate: info.startdate ? new Date(info.startdate) : null,
      dEnddate: info.enddate ? new Date(info.enddate) : null,
      dPrevenddate: info.prevenddate ? new Date(info.prevenddate) : null,
      dFirstworkstartdate: info.firstworkstartdate ? new Date(info.firstworkstartdate) : null,
      aOtRate: this.parser.dbArrayToArray(info.otrate),
      mealPenaltyFee: this.contractSvc.getFirstValuableOtRate(this.parser.dbArrayToArray(info.otrate)),
    }
    return retVal
  }

  getSalaryReasons(tsid: number): Observable<TsCalcInfo> {
    const retVal = this.dataSvc.getSalaryReasons(tsid)
    retVal.subscribe({
      next: data => {

      },
      error: err => {

      }
    })
    return retVal
  }

  isOwnTs(ts?: Timesheet | null): boolean {
    if (!ts?.tsid) return true
    return ts.usid == this.userSvc.loggedinUserId
    // find contract in userContracts
    const userContracts = this.contractSvc.userContracts$.value
    if (!userContracts?.length) return false
    const contract = userContracts.find(x => x.conid === ts?.conid)
    return !!contract
  }

  hasDeptHead(ts?: Timesheet | null): boolean {
    if (!ts?.tsid || !ts.depid) return false
    const dept = this.deptSvc.getDeptById(ts.depid)
    return !!dept?.depleaders?.length
  }

  canDeptHeadApprove(ts?: Timesheet | null): ButtonStatus {
    if (!ts?.tsid) return { disabled: true, title: this.t7e.getTranslation("timesheet.service", "canApprove", "notsid",'Nincs elmentve vagy ismeretlen timesheet') }
    if (ts.tsstatus == TimesheetStatus.deleted) return { disabled: true, title: this.t7e.getTranslation("timesheet.service", "canApprove", "deleted",'Törölve van') }
    if (ts.tsstatus == TimesheetStatus.disabled) return { disabled: true, title: this.t7e.getTranslation("timesheet.service", "canApprove", "disabled", 'A Timesheet le van tiltva') }
    if (ts.tsstatus == TimesheetStatus.atCrewMember) return { disabled: true, title: this.t7e.getTranslation("timesheet.service", "canApprove", "atCrewMember",'Már vissza van küldve') }
    if (ts.tsstatus == TimesheetStatus.atDeptHead) return { disabled: false, title: this.t7e.getTranslation("timesheet.service", "canApprove", "approve",'Jóváhagyás') }
    return { disabled: true, title: this.t7e.getTranslation("timesheet.service", "canApprove", "norights",'Nincs jogosultságod a Timesheet jóváhagyásához') }
  }
  canApprove(ts?: Timesheet | null): ButtonStatus {
    if (!ts?.tsid) return { disabled: true, title: this.t7e.getTranslation("timesheet.service", "canApprove", "notsid",'Nincs elmentve vagy ismeretlen timesheet') }
    if (ts.tsstatus == TimesheetStatus.deleted) return { disabled: false, title: this.t7e.getTranslation("timesheet.service", "canApprove", "retoreApproveDeleted",'Törölt timesheet visszaállítása és jóváhagyása') }
    if (ts.tsstatus == TimesheetStatus.disabled && this.userSvc.isProdMgr) return { disabled: false, title: this.t7e.getTranslation("timesheet.service", "canApprove", "disabled",'Letiltott Timesheet jóváhagyása') }
    if (ts.tsstatus == TimesheetStatus.approved) return { disabled: true, title: this.t7e.getTranslation("timesheet.service", "canApprove", "approved",'Már jóvá van hagyva') }
    if (this.userSvc.isProdMgr) return { disabled: false, title: this.t7e.getTranslation("timesheet.service", "canApprove", "approvetimeandsum",'Idők és összegek jóváhagyása') }
    if (this.userSvc.isFinance) {
      const allowedFrom = [TimesheetStatus.attGenerating, TimesheetStatus.attSent, TimesheetStatus.invAccepted]
      if (allowedFrom.includes(ts.tsstatus!)) return { disabled: false, title: this.t7e.getTranslation("timesheet.service", "canApprove", "resetapprove",'Státusz visszaállítása jóváhagyottra') }
    }
    return { disabled: true, title: this.t7e.getTranslation("timesheet.service", "canApprove", "norights",'Nincs jogosultságod a Timesheet jóváhagyásához') }
  }
  canSendBackToCrew(ts?: Timesheet | null): ButtonStatus {
    if (!ts?.tsid) return { disabled: true, title: this.t7e.getTranslation("timesheet.service", "canSendBackToCrew", "notsaved",'Nincs elmentve vagy ismeretlen timesheet') }
    if (ts.tsstatus == TimesheetStatus.deleted) return { disabled: false, title: this.t7e.getTranslation("timesheet.service", "canSendBackToCrew", "deleted",'Törölt timesheetet visszaküldesz a stábtagnak') }
    if (ts.tsstatus == TimesheetStatus.atCrewMember) return { disabled: true, title: this.t7e.getTranslation("timesheet.service", "canSendBackToCrew", "atCrewMember",'Már vissza van küldve') }
    if (this.userSvc.isProdMgr || this.userSvc.isFinance) return { disabled: false, title: this.t7e.getTranslation("timesheet.service", "canSendBackToCrew", "isProdMgr",'A Timesheet visszaküldése a stábtagnak javításra') }
    if (this.userSvc.isDeptHead && ts.tsstatus == TimesheetStatus.atDeptHead) return { disabled: false, title: this.t7e.getTranslation("timesheet.service", "canSendBackToCrew", "isDeptHead",'A Timesheet visszaküldése a stábtagnak javításra') }
    if (ts.tsstatus == TimesheetStatus.disabled) return { disabled: true, title: this.t7e.getTranslation("timesheet.service", "canSendBackToCrew", "disabled",'A Timesheet le van tiltva') }
    return { disabled: true, title: this.t7e.getTranslation("timesheet.service", "canSendBackToCrew", "norights",'Nincs jogosultságod a Timesheet visszaküldéséhez') }
  }
  canRetractApproval(ts?: Timesheet | null): ButtonStatus {
    if (!ts?.tsid) return { disabled: true, title: this.t7e.getTranslation("timesheet.service", "canRetractApproval", "notsaved",'Nincs elmentve vagy ismeretlen timesheet') }
    if (ts.tsstatus == TimesheetStatus.deleted) return { disabled: false, title: this.t7e.getTranslation("timesheet.service", "canRetractApproval", "deleted",'Törölt timesheetet gyártásvezetőnek küldesz') }
    if (ts.tsstatus == TimesheetStatus.disabled) return { disabled: true, title: this.t7e.getTranslation("timesheet.service", "canRetractApproval", "disabled",'A Timesheet le van tiltva') }
    if (ts.tsstatus == TimesheetStatus.atCrewMember) return { disabled: true, title: this.t7e.getTranslation("timesheet.service", "canRetractApproval", "atCrewMember",'Már vissza van küldve stábtagnak') }
    const beforeApproved = [TimesheetStatus.atDeptHead, TimesheetStatus.deptHeadApproved, TimesheetStatus.atProdMgr, TimesheetStatus.deleted, TimesheetStatus.disabled]
    if (beforeApproved.includes(ts.tsstatus!)) return { disabled: true, title: this.t7e.getTranslation("timesheet.service", "canRetractApproval", "atProdMgr",'Nincs még jóváhagyva, vagy a jóváhagyás vissza lett vonva') }
    const allowedFrom = [TimesheetStatus.approved, TimesheetStatus.attGenerating, TimesheetStatus.attSent, TimesheetStatus.invAccepted]
    if (this.userSvc.isProdMgr || this.userSvc.isFinance && allowedFrom.includes(ts.tsstatus!)) return { disabled: false, title: this.t7e.getTranslation("timesheet.service", "canRetractApproval", "retractapp",'A Timesheet jóváhagyásának visszavonása') }
    else return { disabled: true, title: this.t7e.getTranslation("timesheet.service", "canRetractApproval", "norights",'Nincs jogosultságod a Timesheet jóváhagyásának visszavonásához') }
  }
  canSetPdfSent(ts?: Timesheet | null): ButtonStatus {
    if (!ts?.tsid) return { disabled: true, title: this.t7e.getTranslation("timesheet.service", "canSetPdfSent", "notsaved",'Nincs elmentve vagy ismeretlen timesheet') }
    if (ts.tsstatus == TimesheetStatus.deleted) return { disabled: true, title: this.t7e.getTranslation("timesheet.service", "canSetPdfSent", "deleted",'Törölve van') }
    if (ts.tsstatus == TimesheetStatus.disabled) return { disabled: true, title: this.t7e.getTranslation("timesheet.service", "canSetPdfSent", "disabled",'A Timesheet le van tiltva') }
    if (ts.tsstatus == TimesheetStatus.atCrewMember
      || ts.tsstatus == TimesheetStatus.atProdMgr) return { disabled: true, title: this.t7e.getTranslation("timesheet.service", "canSetPdfSent", "notapproved",'Még nincs jóváhagyva') }
    if (ts.tsstatus == TimesheetStatus.attSent) return { disabled: true, title: this.t7e.getTranslation("timesheet.service", "canSetPdfSent", "alreadystatus",'Már ebben a státuszban van') }
    if (this.userSvc.isFinance) return { disabled: false, title: this.t7e.getTranslation("timesheet.service", "canSetPdfSent", "tssentemail",'A Timesheetet kiküldted emailben') }
    return { disabled: true, title: this.t7e.getTranslation("timesheet.service", "canSetPdfSent", "norights",'Nincs jogosultságod a Timesheet kiküldéséhez') }
  }
  canSetInvoiceAccepted(ts?: Timesheet | null): ButtonStatus {
    if (!ts?.tsid) return { disabled: true, title: this.t7e.getTranslation("timesheet.service", "canSetInvoiceAccepted", "notsaved",'Nincs elmentve vagy ismeretlen timesheet') }
    if (ts.tsstatus == TimesheetStatus.deleted) return { disabled: true, title: this.t7e.getTranslation("timesheet.service", "canSetInvoiceAccepted", "deleted",'Törölve van') }
    if (ts.tsstatus == TimesheetStatus.disabled) return { disabled: true, title: this.t7e.getTranslation("timesheet.service", "canSetInvoiceAccepted", "disabled",'A Timesheet le van tiltva') }
    if (ts.tsstatus == TimesheetStatus.atCrewMember
      || ts.tsstatus == TimesheetStatus.atProdMgr) return { disabled: true, title: this.t7e.getTranslation("timesheet.service", "canSetInvoiceAccepted", "atProdMgr",'Még nincs jóváhagyva') }
      if (ts.tsstatus == TimesheetStatus.invAccepted) return { disabled: true, title: this.t7e.getTranslation("timesheet.service", "canSetInvoiceAccepted", "invAccepted",'A számla már be lett fogadva') }
    if (this.userSvc.isFinance) return { disabled: false, title: this.t7e.getTranslation("timesheet.service", "canSetInvoiceAccepted", "invfinaccepted",'A számla be lett fogadva') }
    return { disabled: true, title: this.t7e.getTranslation("timesheet.service", "canSetInvoiceAccepted", "norights",'Nincs jogosultságod a számla jóváhagyásához') }
  }
  canRemoveTs(ts?: Timesheet | null): ButtonStatus {
    if (!ts?.tsid) return { disabled: true, title: this.t7e.getTranslation("timesheet.service", "canRemoveTs", "notsaved",'Nincs elmentve vagy ismeretlen timesheet') }
    if (ts.tsstatus === TimesheetStatus.deleted) return { disabled: true, title: this.t7e.getTranslation("timesheet.service", "canRemoveTs", "deleted",'Már törölve van') }
    if (ts.tsstatus == TimesheetStatus.invAccepted) return { disabled: true, title: this.t7e.getTranslation("timesheet.service", "canRemoveTs", "invAccepted",'A számla már be lett fogadva') }
    if (this.userSvc.isProdMgr) return { disabled: false, title: this.t7e.getTranslation("timesheet.service", "canRemoveTs", "inventerederror", 'A Timesheet tévesen lett bevive') }
    if (this.userSvc.isDeptHead && ts.tsstatus == TimesheetStatus.atDeptHead) return { disabled: false, title: this.t7e.getTranslation("timesheet.service", "canRemoveTs", "inventerederror", 'A Timesheet tévesen lett bevive') }
    if (this.isOwnTs(ts)) return { disabled: false, title: 'Törlés. A törölt Timesheet szükség esetén visszaállítható' }
    return { disabled: true, title: this.t7e.getTranslation("timesheet.service", "canRemoveTs", "norights",'Nincs jogosultságod a Timesheet törléséhez') }
  }
  canDelete = this.canRemoveTs

  canGenerateAtt(ts?: TimesheetParsed | null): boolean {
    if (!ts?.tsid) return false
    if (ts?.tsstatus !== TimesheetStatus.approved) return false
    if (!this.userSvc.isFinance) return false
    return true
  }
  isAtProdMgr(ts?: TimesheetParsed | null): boolean {
    if (ts?.tsstatus == TimesheetStatus.atProdMgr) return true
    if (ts?.tsstatus == TimesheetStatus.deptHeadApproved) return true
    return false
  }
  isApprovedOrHigher(ts?: TimesheetParsed | null): boolean {
    if (!ts?.tsid) return false
    if (ts?.tsstatus! < 0) return false
    if (ts?.tsstatus == TimesheetStatus.atCrewMember) return false
    if (ts?.tsstatus == TimesheetStatus.atDeptHead) return false
    if (ts?.tsstatus == TimesheetStatus.deptHeadApproved) return false
    if (ts?.tsstatus == TimesheetStatus.atProdMgr) return false
    return true
  }
  isDisabled(ts?: Timesheet | null): boolean {
    return ts?.tsstatus == TimesheetStatus.disabled
  }

  readonly statusesApprovedOrHigher = [
    TimesheetStatus.approved,
    TimesheetStatus.attGenerating,
    TimesheetStatus.attSent,
    TimesheetStatus.invAccepted,
  ]

  isSent(ts?: TimesheetParsed | null): boolean {
    if (!ts?.tsid) return false
    if (ts?.tsstatus == TimesheetStatus.attSent) return true
    if (ts?.tsstatus == TimesheetStatus.invAccepted) return true
    return false
  }

  canEdit(ts: TimesheetParsed | null): boolean {
    if (this.userSvc.isDev) return true
    if (!ts?.tsid) return true
    if (this.userSvc.isProdMgr) {
      if (this.isAtProdMgr(ts)) return true
      if (this.isApprovedOrHigher(ts)) return true
      if (ts.tsstatus == TimesheetStatus.disabled) return true
    }
    if (this.userSvc.isFinance) {
      if (this.isAtProdMgr(ts)) return true
      if (this.isApprovedOrHigher(ts)) return true
    }
    if (this.userSvc.isDeptHead) {
      if (ts?.tsstatus == TimesheetStatus.atDeptHead) return true
      if (ts.tsstatus == TimesheetStatus.disabled) return true
    }
    if (this.userSvc.isCrew || this.contractSvc.isDeptAdminOf(ts.depid)) {
      if (ts?.tsstatus == TimesheetStatus.atCrewMember) return true
      if (ts.tsstatus == TimesheetStatus.disabled) return false
    }
    return false
  }


  /** SZÁMLAMELLÉKLET GENERÁLÁS */
    /**
 * 
 * @param documentType Meghívja a szlamelléklet generáló endpointot
 * @param params endpoint paraméterek
 * @param sendTo false==ne küldd ki; 'string'==a 'string'-re küldd az emailt; null==a szerződőnek küldd az emailt
 * @param isDraft true==not saved in contract, false==will be saved in contract
 * @param docId ha null, akkor új Google Doc-t generál, ha nem null, akkor a meglévőt menti le pdf-ben és küldi ki emailen
 * @returns 
 */
  generateDoc(
    documentType: DocType,
    params: GenerateDocParams[],
    sendTo: string | false | null = null,
    isDraft: boolean,
    savePdf: boolean,
  ): Observable<PrintdocResponse | null> {
    const retVal = this.dataSvc.generateDoc(documentType, params, sendTo, isDraft, savePdf)
      retVal.subscribe({
        next: response => {
          console.log('gen doc válasz:', response)
          params.forEach(async c => {
            const p: ListTimesheetsParams = {
              _startdate: c['startdate'] as string,
              _enddate: c['enddate'] as string,
              _usid: c['usid'] as number | undefined,
              _conid: c['conid'] as number | undefined,
            }
            this.listTs(p)
          })
        },
        error: err => {
          console.error(err);
        }
      })
      return retVal
    }
  
}
