import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { BehaviorSubject, Subject, Subscription, combineLatest, firstValueFrom, map, takeUntil, tap, timeout } from 'rxjs';
import { DbCallError, AppService, ButtonStatus, NUMERIC_FIELD_TO_DELETE } from '../../app.service';
import { ContractService } from '../../contract/contract.service';
import { CalcSalaryParams, CheckTimesheetParams, DayOptions, HandleTimesheetsParams, TimesheetParsed, TimesheetStatus } from '../../models/timesheet';
import { TimesheetService } from '../timesheet.service';
import { NotificationService } from '../../util/notifications/notification.service';
import { CheckTs } from '../adjacent-timesheets/adjacent-timesheets.component';
import { ContractParsed } from '../../models/contract';
import { UserService } from '../../user.service';
import { NumericValidator } from '../../util/validators/validators';
import { FlexTables } from '../../data.service';
import { T7eService } from '../../t7e/t7e.service';
import { ParserService, SQL_DATE_FORMAT } from '../../parser.service';
import { ProductionService } from '../../prod/production.service';
import { ShootingLocation } from '../../models/location';
import { config } from '../../config';
import { FeeFields } from './ts-editor-readonly/ts-editor-readonly.component';

@Component({
  selector: 'app-edit-timesheet',
  templateUrl: './edit-timesheet.component.html',
  styleUrls: ['./edit-timesheet.component.scss']
})
export class EditTimesheetComponent implements OnInit, OnDestroy {
  readonly DISPLAY_SALARY_OVERRIDE_FIELD = false
  readonly MEAL_PENALTY = config.HU.TS.MEAL_PENALTY
  ALLOW_PERDIEM = config.ALLOW_PERDIEM
  ALLOW_REST_DAY = config.ALLOW_REST_DAY
  MULTIPLE_CURRENCIES = config.MULTIPLE_CURRENCIES

  _destroy$ = new Subject<void>()

  private _ts$ = new BehaviorSubject<TimesheetParsed | null>(null)
  private get _ts() { return this._ts$.value }

  @Input()
  set timesheet(value: TimesheetParsed | null) {
    // for (let i = 0; i < this.adjacentTsParams$.length; i++) {
    //   this.removeAdjacentCheck(i)
    // }

    this._ts$.next(value)
  }
  
  private _dates: Date[] = []
  @Input() 
  set dates(value: Date[] | null | undefined) {
    if (value) {
      this._dates = value
      this.setDates(value)
    } else {
      this._dates = []
      this.setDates([new Date()])
    }
  }
  get dates(): Date[] | null | undefined {
    return this._dates
  }

  @Input() layout: 'grid-form' | 'vertical-form' = 'vertical-form'
  @Input() showContract: boolean = true
  @Input() showStatus: boolean = true
  @Input() canAddDate: boolean = true
  @Output() selectedDateChange = new EventEmitter<Date[]>()
  @Output() timesheetChange = new EventEmitter<TimesheetParsed | null>()
  
  readonly AUTOFILL_TIMES = false

  get isOwnTs() { return !this._ts?.usid ? true : this._ts?.usid === this.userSvc?.loggedinUser$.value?.usid }
  get isDebug() { return this.appSvc.debug}
     
  form: FormGroup = this.getFG({ dStartDate: new Date(), dEndDate: new Date() }) // csak azért, hogy ne nyüszítsen a TS, hogy nincs form
  minDate: Date | null = null
  maxDate: Date | null = moment().endOf('day').toDate()

  isLoading: boolean = false
  isSavingError$ = new BehaviorSubject<DbCallError>(null)
  isSavingError: DbCallError = false
  isSaving: boolean = false

  getUserById = this.userSvc.getUserById
  userContracts$ = this.contractSvc.userContracts$
  currContract$ = new BehaviorSubject<ContractParsed | null>(null)
  adjacentTsParams$: BehaviorSubject<HandleTimesheetsParams | null>[] = []
  aOverlapse$: BehaviorSubject<CheckTs | null>[] = []
  aOverlapseSubscriptioins: Subscription[] = []
  locations$ = new BehaviorSubject<ShootingLocation[] | null>(null)
  mealPenaltyRate: number | null = null;
  history?: { tableid: number, id: number, propcode?: string, colname?: string}
  showHistoryButtons: boolean = false
  warnSalaryOverride$ = new BehaviorSubject<('halfDay' | 'travelDay' | 'restDay')[]>([])
  exchangeRatesLoading$ = this.prodSvc.currencyExchangeRatesLoading$
  exchangeRatesError$ = this.prodSvc.currencyExchangeRatesError$
  exchangeRates$ = this.prodSvc.currencyExchangeRates$
  isExchangeRateReady$ = new BehaviorSubject<boolean>(false)
  showSaveComment: { title: string, saveButtonText: string, isCommentOptional: boolean, newStatus: TimesheetStatus } | null = null
  selectedReadOnlyFields: FeeFields = 'calc'
  workhourValueChangeSubscription?: Subscription

  constructor(
    private tsSvc: TimesheetService,
    private notifSvc: NotificationService,
    private appSvc: AppService,
    private contractSvc: ContractService,
    private userSvc: UserService,
    private prodSvc: ProductionService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private t7e: T7eService,
    private parser: ParserService,
  ) {
    this.appSvc.pageTitle$.next(this.t7ePageTitle)
    this.setDates = this.setDates.bind(this)
    this.scrollFormIntoView = this.scrollFormIntoView.bind(this)
    this.warnHoliday = this.warnHoliday.bind(this)

    this.prodSvc.fetchCurrencyExchangeRates()

    combineLatest([this.currContract$, this.exchangeRatesLoading$, this.exchangeRatesError$])
      .pipe(
        takeUntil(this._destroy$),
        map(([contract, exchangeRatesLoading, exchangeRatesError]) => {
          // console.log('retVal, f_currency, exchangeRatesLoading, exchangeRatesError', contract?.f_currency === this.prodSvc.getCurrency()
          //   || (!exchangeRatesLoading && exchangeRatesError === false), contract?.f_currency, exchangeRatesLoading, exchangeRatesError)
          return contract?.f_currency === this.prodSvc.getCurrency()
            || (!exchangeRatesLoading && exchangeRatesError === false)
        })
      )
      .subscribe(this.isExchangeRateReady$)

    // csak azért, hogy ne nyüszítsen a TS, hogy nincs form
    this.form = this.getFG({ dStartDate: new Date(), dEndDate: new Date() })

    this.isSavingError$
      .pipe(takeUntil(this._destroy$))
      .subscribe(x => this.isSavingError = x)

    this._ts$
      .pipe(takeUntil(this._destroy$))
      .subscribe({
      next: (ts) => {
        console.log('ts', ts?.tsid, ts?.depname, ts?.usname, ts?.dStartDate)
        this.selectedReadOnlyFields = this.tsSvc.isApprovedOrHigher(ts) ? 'saved' : 'calc'
        if (ts?.tsid) {
          // call removeDate until there is only one date
          while (this.datesFA?.length > 1) {
            this.removeDate(1)
          }
          this.form = this.getFG(ts)
          this.currContract$.next(contractSvc.getContractById(ts.conid!))
          this.onKmOrCommentChange(0)
        } else { 
          this.form = this.getFG(ts)
          this.setDates(this._dates)
          this.currContract$.next(this.contractSvc.selectedContract)
        }

          this.workhourValueChangeSubscription?.unsubscribe()
          this.workhourValueChangeSubscription = this.form.get('workhours')?.valueChanges
            .pipe(takeUntil(this._destroy$))
            .subscribe(this.onWorkhoursChange.bind(this))

        if (!this.canEdit(ts)) {
          // valamiért setTimeout() nélkül csak a workhours és a to és from time lesz disabled
          setTimeout(() => { this.form.disable() }, 0)
        } else {
          if (!this.userSvc.isMoreThanDeptHead) {
            this.form.get('salaryOverride')?.disable()
          }
        }
          
          if (ts) this.mealPenaltyRate = this.contractSvc.getFirstValuableOtRateByContract(this.currContract$.value)

        this.checkTs()
      },
    })
  
    this.currContract$
      .pipe(takeUntil(this._destroy$))
      .subscribe(this.scrollFormIntoView)
    
    this.prodSvc.locations$.pipe(
      map(x => x
        ?.sort((a, b) => a.locName.localeCompare(b.locName))
        .filter(x => x.enabled)
        || []
      )
    ).subscribe(this.locations$)

    if (!this.locations$.value?.length) this.prodSvc.fetchShootingLocations()
  }

  onLocationChange(dateIndex: number, locCode: string): void {
    if (locCode && !confirm(this.t7eLocationConfirmEligibleText)) {
      this.getDateFG(dateIndex).get('location')?.setValue('')
      return
    }
    const location: ShootingLocation | null = !locCode
      ? null
      : this.prodSvc.locations$.value?.find(l => l.locCode == locCode) || null
    const kmFee = this.convertIfNecessary(location?.kmFee || 0, this.getDateFG(dateIndex).value.fromDate)
    this.getDateFG(dateIndex).get('kmFee')?.setValue(kmFee) || 0
    this.getDateFG(dateIndex).get('kmComment')?.setValue(location?.locName) || ''
    this.disableKmAndComment(dateIndex, locCode)
  }

  private disableKmAndComment(dateIndex: number, locCode: string): boolean {
    if (!!locCode?.trim()) {
      this.getDateFG(dateIndex).get('kmFee')?.disable()
      this.getDateFG(dateIndex).get('kmComment')?.disable()
      return true
    }
    else {
      this.getDateFG(dateIndex).get('kmFee')?.enable()
      this.getDateFG(dateIndex).get('kmComment')?.enable()
      return false
    }
  }

  onKmOrCommentChange(dateIndex: number): void {
    if (this.getDateFG(dateIndex)?.get('location')?.getRawValue()?.trim()) return
    const kmFee = this.getDateFG(dateIndex).get('kmFee')?.getRawValue()?.toString().trim()
    const isKmFeeZero = parseInt(kmFee) === 0
    const kmComment = this.getDateFG(dateIndex).get('kmComment')?.getRawValue() ?.trim()
    if ((!!kmFee && !isKmFeeZero) || !!kmComment) {
      this.getDateFG(dateIndex)?.get('location')?.disable()
    } else {
      this.getDateFG(dateIndex)?.get('location')?.enable()
    }
  }

  private convertIfNecessary(fee: number | null, date: Date): number | null {
    if (!this.MULTIPLE_CURRENCIES || this.currContract$.value?.f_currency === this.prodSvc.getCurrency()) return fee
    if (!fee) return 0
    const showErrMsg = () => {
      const msg = this.t7e.lang === 'hu'
        ? `A ${this.currContract$.value?.f_currency} árfolyam nem áll rendelkezésre. Töltsd ki kézzel a km díjat`
        : `The  ${this.currContract$.value?.f_currency} is not available. Fill the km fee manually`
      this.notifSvc.alert(msg, this.currContract$.value?.f_currency || '')
    }
    if (!this.isExchangeRateReady$.value) {
      showErrMsg()
      return null
    }
    try {
      const rate = this.MULTIPLE_CURRENCIES === 'fixed-rates'
        ? this.prodSvc.getExchangeRateForFixedRates(this.currContract$.value?.f_currency)
        : this.prodSvc.getCurrencyExchangeRateByDate(date)      
      return Math.round(fee * 100 / rate) / 100
    } catch (error) {
      console.error('Error while converting currency:', error)
      showErrMsg()
      return null
    }
  }

  private onWorkhoursChange(workhours: number | null): void {
    console.log('workhours', workhours)
    this.checkTs()
    if (this.AUTOFILL_TIMES) {
      const tsWithNewTimes = this.setDatesIfNew(this.form.value)
      if (tsWithNewTimes) {
        this.form.get('fromTime')?.setValue(moment(tsWithNewTimes?.dStartDate).format('HH:mm'))
        this.form.get('toTime')?.setValue(moment(tsWithNewTimes?.dEndDate).format('HH:mm'))
      }
    } else {
      this.focusFromTime()
    }
    this.scrollFormIntoView()
  }

  private focusFromTime() {
    setTimeout(() => {
      const fromTime = document.getElementById('fromTime') as HTMLInputElement
      if (fromTime) {
        fromTime.focus()
        fromTime.select()
      }
    }, 0)
  }

  get ts() { return this._ts }
  get isEdit() { return !!this._ts?.tsid }
  get datesFA() { return this.form.get('dates') as FormArray }
  get isAtCrewMember() { return !this._ts?.tsid ||  this._ts$?.value?.tsstatus == TimesheetStatus.atCrewMember }
  get isDeleted() { return this._ts$?.value?.tsstatus == TimesheetStatus.deleted }
  get isDisabled() { return this._ts$?.value?.tsstatus == TimesheetStatus.disabled }
  get statusClass() {
    return this.tsSvc.getStatusClass(this.ts)
  }
  get statusName() {
    return this.tsSvc.getStatusName(this.ts?.tsstatus)
  }
  get statusSaveComment() {
    return this.ts?.savecomment
  }
  get statusOf() { return this._ts?.tsid ? {tableId: FlexTables.timesheets, id: this._ts?.tsid} : undefined }
  get showContractSection() {
    return this.showContract && (
      this.userContracts$.value?.length != 1
      // nem egy törölt szerződéshez tartozik?
      || this._ts$?.value?.conid == this.userContracts$.value?.[0]?.conid
    )
  }
  get tsWorkhours() { return this.form?.getRawValue().workhours }
  get isTimeSet() {
    if  (this._ts?.tsid) return true
    if (this.form?.get('fromTime')?.dirty) return true
    if (this.form?.get('toTime')?.dirty) return true
    return false
  }
  get isWaitingForProdMgr() {
    const statuses: (TimesheetStatus | null | undefined)[] = [
      TimesheetStatus.atProdMgr,
      TimesheetStatus.deptHeadApproved,
    ]
    return statuses.includes(this._ts?.tsstatus)
  }
  get isApprovedOrHigher() { return this.tsSvc.isApprovedOrHigher(this._ts) }
  get rentalName1() { return this.currContract$.value?.f_dailybasedrentalrate1name }
  get rentalFee1() { return this.currContract$.value?.f_dailybasedrentalrate1 }
  get rentalName2() { return this.currContract$.value?.f_dailybasedrentalrate2name }
  get rentalFee2() { return this.currContract$.value?.f_dailybasedrentalrate2 }
  get rentalName3() { return this.currContract$.value?.f_dailybasedrentalrate3name }
  get rentalFee3() { return this.currContract$.value?.f_dailybasedrentalrate3 }
  get rentalName4() { return this.currContract$.value?.f_dailybasedrentalrate4name }
  get rentalFee4() { return this.currContract$.value?.f_dailybasedrentalrate4 }
  get rentalName5() { return this.currContract$.value?.f_dailybasedrentalrate5name }
  get rentalFee5() { return this.currContract$.value?.f_dailybasedrentalrate5 }
  get rentalName6() { return this.currContract$.value?.f_dailybasedrentalrate6name }
  get rentalFee6() { return this.currContract$.value?.f_dailybasedrentalrate6 }
  get hasMonthlyRents() {
    return !!(this.currContract$.value?.f_monthlybasedrentalrate1
      || this.currContract$.value?.f_monthlybasedrentalrate2
      || this.currContract$.value?.f_monthlybasedrentalrate3)
  }
  get contractHasMealPenalty() { return this.contractSvc.hasMealPenalty_setAtProdDeptSf(this.currContract$.value) }
  contractMealPenaltyRate = this.contractSvc.getMealPenaltyRate_setAtProdDeptSf

  isProdMgr = this.userSvc.isProdMgr
  isFinance = this.userSvc.isFinance
  isDeptHead = this.userSvc.isDeptHead
  isOnlyDeptAdmin = this.userSvc.isOnlyDeptAdmin
  isMoreThanCrew = this.userSvc.isMoreThanCrew
  isMoreThanDeptHead = this.userSvc.isMoreThanDeptHead
  canEdit = this.tsSvc.canEdit
  canDelete = this.tsSvc.canDelete
  canDeptHeadApprove = this.tsSvc.canDeptHeadApprove
  canApprove = this.tsSvc.canApprove
  canSendBack = this.tsSvc.canSendBackToCrew
  canGenerateAtt = this.tsSvc.canGenerateAtt
  canSetAttSent = this.tsSvc.canSetPdfSent
  canSetInvAccepted = this.tsSvc.canSetInvoiceAccepted
  
  lang = this.t7e.lang
  get hasInvoiceContact() { return this.contractSvc.hasInvoiceContact(this.currContract$.value) }
  get currency() { return this.currContract$.value ? this.contractSvc.getCurrency(this.currContract$.value) : ''}

  get canSubmit(): ButtonStatus {
    if (!this._ts?.conid) return { disabled: true, title: this.t7eBtnSubmitNoContractTitle }
    if (!this.form.valid) return { disabled: true, title: this.t7eBtnSubmitFormInvalidTitle }
    if (this._ts.tsid && this.form.dirty) return { disabled: true, title: this.t7eBtnSubmitFormNotSavedTitle }
    if (this.isSaving) return { disabled: true, title: this.t7eBtnSubmitSavingTitle }
    if (this.aOverlapse$
      .some(x => (x?.value?.sametsid != this._ts?.tsid || this._ts?.tsid === null)
        && x.value?.overlaptsid)) return { disabled: true, title: this.t7eBtnSubmitOverlapTitle }
    if (this.userSvc.isProdMgr) {
      switch (this._ts.tsstatus) {
        case TimesheetStatus.invAccepted:
          return { disabled: true, title: this.t7eBtnSubmitInvAcceptedTitle };
        case TimesheetStatus.attGenerating:
          return { disabled: true, title: this.t7eBtnSubmitAttGeneratingTitle };
      }
    } else if (this.userSvc.isDeptHead) {
      if (this.isDateFuture()) return { disabled: true, title: this.t7eBtnSubmitFutureTitle }
      if (this._ts.tsid && this._ts.tsstatus != TimesheetStatus.atDeptHead) {
        if (this._ts.tsid && this._ts.tsstatus != TimesheetStatus.atCrewMember) {
          return { disabled: true, title: this.t7eBtnSubmitAlreadySubmittedTitle }
        } else {
          return { disabled: false, title: '' } 
        }
      }
    } else if (this.userSvc.isCrew) {
      // jövőbeni ts-t nem lehet beküldeni
      if (this.isDateFuture()) return { disabled: true, title: this.t7eBtnSubmitFutureTitle }
      if (this._ts.tsid && this._ts.tsstatus != TimesheetStatus.atCrewMember) {
        return { disabled: true, title: this.t7eBtnSubmitAlreadySubmittedTitle }
      }
    } else {
      return { disabled: true, title: this.t7eBtnSubmitNoPermissionTitle }
    }
      
    return { disabled: false, title: '' }
  }

  private isDateFuture(): boolean {
    const dEndDate = this.form.getRawValue().dates.reduce((acc: Date, curr: any, i: number) => {
      let { enddate } = this.getTimes(i)
      return moment(enddate).isAfter(moment(acc)) ? enddate : acc
    }, new Date(0))
    return moment(dEndDate).isAfter(moment())
  }
  
  async ngOnInit(): Promise<void> {
    // Ha nincs @Input() timesheet, akkor próbáljuk meg betölteni a route-ból
    if (!this._ts) {
      const routeParams = this.route.snapshot.paramMap
      const tsidFromRoute = Number(routeParams.get('tsid')) || null
      if (!tsidFromRoute) {
        console.error('ts null');
        return
      }
      try {
        this.isLoading = true
        const tsFromRoute = await firstValueFrom(this.tsSvc.listTs({ _tsid: tsidFromRoute }))
        if (!tsFromRoute?.length) {
          console.error('Ismeretlen ts (route-ból): ' + tsidFromRoute);
          this.notifSvc.addObservableNotif({ msg: this.t7eNotifUnknownTs, class: 'danger' })
          return
        }
        if (tsFromRoute.length !== 1) {
          console.error('Több ts ugyanazzal a tsid-val (route-ból): ' + tsidFromRoute);
          this.notifSvc.addObservableNotif({ msg: this.t7eNotifMultipleTsFromRouteError, class: 'danger' })
          return
        }
        this.timesheet = tsFromRoute[0] && this.tsSvc.parseTs(tsFromRoute[0])
        if (!this.isOwnTs) this.showContract = true
        this.currContract$.next(this.contractSvc.getContractById(tsFromRoute[0].conid))
      } catch (err) {
        console.error(err);
        this.notifSvc.addObservableNotif({ msg: this.t7eNotifTsLoadError, class: 'danger' })
      } finally {
        this.isLoading = false
      }
    }
    // Az @Input() timesheet vagy a route-ból betöltött timesheet
    /// ez kell??  if (this._dates.length) this.setDates(this._dates)

  }

  ngOnDestroy(): void {
    this._destroy$.next()
    this._destroy$.complete()
    this.aOverlapseSubscriptioins.forEach(x => x.unsubscribe())
  }

  getDateFG(dateIndex: number): FormGroup {
    return this.datesFA.controls[dateIndex] as FormGroup
  }

  hasPerdiem(dateIndex: number): boolean {
    return !!this.getDateFG(dateIndex).get('perdiem')?.value
  }

  get canSave(): ButtonStatus {
    if (this.isDebug) return { disabled: false, title: this.t7eBtnSaveDebugTitle }
    if (this.isSaving) return { disabled: true, title: this.t7eBtnSaveSavingTitle }
    if (!this.form.dirty) return { disabled: true, title: this.t7eBtnSaveNoChangeTitle }
    if (!this.form.valid) return { disabled: true, title: this.t7eBtnSaveFormInvalidTitle }
    else return { disabled: false, title: this.t7eBtnSaveTitle }
  }

    /** Ha új TS-t szeretnénk létrehozni, akkor a dStartDate-t és dEndDate-t állítsuk be */
  private setDatesIfNew(ts: TimesheetParsed | null): TimesheetParsed | null {
    if (this.isTimeSet) return ts
    if (ts?.tsid) return ts
    if (this.AUTOFILL_TIMES) return ts
    const wh = this.tsWorkhours || this.contractSvc.getDayLen()
    if (!wh) return ts
    
    ts!.dStartDate = moment().subtract(wh, 'hours').toDate()
    ts!.dEndDate = moment().toDate()
    
    return ts

  }

  private save(params: HandleTimesheetsParams[],
    successText: string | null, errorText: string | null,
    hNotifSave?: number | null,
    successHandler?: (timesheets: TimesheetParsed[]) => any,
    errorHandler?: (err: any) => void): number {
    
    this.isSaving = true
    this.isSavingError$.next(null)
    hNotifSave = this.appSvc.isNumber(hNotifSave)
      ? hNotifSave 
      : this.notifSvc.addObservableNotif({ msg: this.t7eNotifSubmitting, duration: false, class: 'info' })
    this.tsSvc.handleMultipleTs(params)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.isSaving = false
          this.isSavingError$.next(false)
          if (response?.length !== params.length) {
            this.notifSvc.modifyNotif(hNotifSave!, { msg: 'Adatbázis hiba. Talán nem sikerült a mentés', class: 'danger' })
            this.tsSvc.listTs({})
          } else {
            successText && this.notifSvc.modifyNotif(hNotifSave!, { msg: successText, class: 'success', duration: 2000 })
            this.timesheet = response[0]
            successHandler && successHandler(response)
          }
        },
        error: err => {
          const parsedErr = this.parser.parseSqlErrMsg(err)
          this.isSaving = false
          errorText && this.notifSvc.modifyNotif(hNotifSave!, { msg: errorText + parsedErr, class: 'danger' })
          this.isSavingError$.next(err)
          errorHandler && errorHandler(err)
        }
      })
    return hNotifSave!
  }

  onSaveClicked(): void {
    const aErr: string[] = []
    for (let i = 0; i < this.datesFA.length; i++) {
      const params = this.getAllSaveParams(i, aErr)
      if (!params) {
        this.notifSvc.addObservableNotif({ msg: this.t7eNotifCannotSaveError + aErr.join("<br>") })
        return
      }
      this.save([params], this.t7eNotifSaveSuccess, this.t7eNotifSaveError)
    }

  }

  /**
   * 
   * @param submitAsDisabled Force submit as disabled without notifications
   */
  async onSubmit(submitAsDisabled: boolean = false, forceStatus?: TimesheetStatus): Promise<void> {
    const aErr: string[] = []
    const aParams: HandleTimesheetsParams[] = []
    for (let i = 0; i < this.datesFA.length; i++) {
      const err = !submitAsDisabled && await this.isTooOldForOt(i)
      if (err) {
        const { startdate } = this.getTimes(i)
        this.notifSvc.confirm({
          message: this.t7eCheckAgeTooOldNoOt,
          title: startdate.local().format('MMM-DD') + ': ' + this.t7eCheckAgeTooOldTitle,
          okButtonText: this.t7eSubmitAsDisabled, cancelButtonText: this.t7eModifyTimes,
          noButtonText: null,
          okCallback: async () => { await this.onSubmit(true) },
          cancelCallback: () => {  },
        })
        continue
      }
      const params = this.getAllSaveParams(i, aErr)
      if (!params) {
        this.notifSvc.addObservableNotif({ msg: i + 1 + this.t7eNotifNthTsCannotSaveError + aErr.join("<br>") })
        continue
      }
      if (this.ts?.tsid) params._tsstatus = this.tsSvc.hasDeptHead(this.ts)
          ? TimesheetStatus.atDeptHead
          : TimesheetStatus.atProdMgr
      else if (submitAsDisabled || this.isOld(i)) {
        params._tsstatus = TimesheetStatus.disabled
        params._savecomment = this.t7eCheckAgeTooOldTitle
        if (!submitAsDisabled) this.notifSvc.alert(this.t7eCheckAgeTooOld, this.t7eCheckAgeTooOldTitle)
      }
      else if (forceStatus !== undefined) {
        params._tsstatus = forceStatus
      }

      if (params) aParams.push(params)
    }
    if (!aParams.length) return // mind régi OT-vel
    this.save(aParams, this.t7eNotifSubmitSuccess, this.t7eNotifSaveErrorColon)
  }

  isOld(i: number): boolean {
    if (!config.DISABLE_OLD_TS_PREP_WEEKS && !config.DISABLE_OLD_TS_SHOOTING_WEEKS) return false
    if (this.isProdMgr) return false
    if (this.ts?.tsid) return false
    // only check for new TS
    const { startdate } = this.getTimes(i)
    // get the monday of the week before last week
    // on monday, they get an extra week
    const weeksToSubtract =
      ((this.prodSvc.isPrep
        ? (config.DISABLE_OLD_TS_PREP_WEEKS || 999)
        : (config.DISABLE_OLD_TS_SHOOTING_WEEKS || 999))
      + (this.isTodayMonday ? 1 : 0))
    const lastMonday = moment().subtract(weeksToSubtract, 'weeks').startOf('isoWeek')
    if (startdate.isBefore(lastMonday)) return true
    return false
  }
  
  /**
   * Checks if the TS is too old for OT
   * @param i nth date
   * @returns if ProdMgr: false. if Prep: false, else if ts is for before the day before yesterday, and the SQL thinks it would get OT>0. In case the db call fails, it return false
   */
  async isTooOldForOt(i: number): Promise<boolean> {
    if (!config.DISABLE_OLD_TS_OT) return false
    if (this.isProdMgr) return false
    if (this.prodSvc.isPrep) return false
    const { startdate, enddate } = this.getTimes(i)
  // if enddate-startdate > workhours, then check if startdate is not older than the day before yesterday
    if (enddate.diff(startdate, 'minutes') > this.tsWorkhours * 60) {
      const dayBeforeYesterday = moment().subtract(2, 'days').startOf('day')
      // Check if either startdate or dayBeforeYesterday falls on a weekend
      if (dayBeforeYesterday.isoWeekday() >= 6) {
        dayBeforeYesterday.subtract(1, 'days'); // substract mutates the object
      }
      if (startdate.isBefore(dayBeforeYesterday)) {
        try {
          const fees = await this.tsSvc.getCalculatedFeesForDay({
            contractId: this._ts!.conid!,
            startDate: startdate, endDate: enddate,
            dayOptions:this.getDayOptions(this.form.getRawValue(), i),
            workingHours: this.tsWorkhours,
            otRates: (this.currContract$.value?.effectiveOtRates || []).map(x => parseFloat(x as string)),
            otStep: this._ts?.overtimestep || 1, // mindegy mennyi, csak ne 0 legyen
            gracePeriod: this.prodSvc.gracePeriod,
          })
          if (fees.calculatedOtFee) return true
          else return false
        } catch (error) {
          console.error('Failed getting OT fees for day')
          console.error(error)
          return false
        }
      }
    }

    return false
  }

  get isTodayMonday(): boolean {
    return moment().startOf('isoWeek').isSame(moment().startOf('day'))
  }

  submitToDeptHead(): void {
    this.onSubmit(false, TimesheetStatus.atDeptHead)
  }

  approveDeptHead(): void {
    if (!this._ts) return
    this.saveNewStatus(TimesheetStatus.deptHeadApproved)
  }

  approve(): void {
    if (!this._ts) return
    this.saveNewStatus(TimesheetStatus.approved)
  }
  sendBack(): void {
    this.showSaveComment = {
      isCommentOptional: false,
      title: this.t7eSendBackAreYouSureTitle,
      newStatus: TimesheetStatus.atCrewMember,
      saveButtonText: this.t7eSendBackSaveButtonText,
    }
  }
  delete(): void {
    this.showSaveComment = {
      isCommentOptional: true,
      title: this.t7eDeleteAreYouSureTitle,
      newStatus: TimesheetStatus.deleted,
      saveButtonText: this.t7eDeleteSaveButtonText,
    }
  }

  saveCommentReceived(comment: string): void {
    console.log(comment)
    this.saveNewStatus(this.showSaveComment?.newStatus!, comment)
    this.showSaveComment = null
  }

  saveNewStatus(newStatus: TimesheetStatus, savecomment: string | null = null): void {
    if (!this._ts?.tsid) return
    let retVal = this.tsSvc.getSaveNewStatusParams(this._ts,newStatus, savecomment)
    this.save([retVal], this.t7eNotifStatusChangeSuccess, this.t7eNotifStatusChangeError)
  }

  onDateFGChange(index?: number): void {
    this.checkTs(index)
    this.emitSelectedDates()
  }

   checkTs(index?: number): void {
    const aErr: string[] = []
    let indices: number[]
    if (index !== undefined) indices = [index]
    else {
      indices = Array(Object.keys(this.datesFA.controls).length).fill(-1).map((_, i) => i)
    }
    for (const i of indices) {
      const params = this.getCheckTimesheetParams(i, aErr)
      //if (!params) continue
      if (!this.adjacentTsParams$[i]) this.adjacentTsParams$[i] = new BehaviorSubject<HandleTimesheetsParams | null>(params)
      else this.adjacentTsParams$[i].next(params)
    }
  }

  private getAllSaveParams(dateIndex: number, aErr: string[]): HandleTimesheetsParams | null {
    if (!this.isValid(aErr)) {
      return null
    }
    const formData = this.form.getRawValue()
    // const fromDate = formData.dates[dateIndex].fromDate
    // let startdate = moment(moment(fromDate).format("YYYY-MM-DD") + ' ' + formData.fromTime, 'YYYY-MM-DD HH:mm').utc()
    // let enddate = moment(moment(fromDate).format("YYYY-MM-DD") + ' ' + formData.toTime, 'YYYY-MM-DD HH:mm').utc()
    const { startdate, enddate } = this.getTimes(dateIndex)
    const dayoptions = this.getDayOptions(formData, dateIndex)
    const salaryOverride = (this.ts?.tsid && this.isProdMgr)
      ?  (this.appSvc.isNumber(formData.dates[dateIndex].salaryOverride)
        ? formData.dates[dateIndex].salaryOverride
        : this.appSvc.isNumber(this.ts.salary) // csak akkor töröljük, ha már volt benne érték
          ? NUMERIC_FIELD_TO_DELETE
          : null)
      : null
    // const newStatus = this.isProdMgr 
    //     ? TimesheetStatus.approved
    //     : TimesheetStatus.atProdMgr
    let retVal: HandleTimesheetsParams = {
      _tsid: this._ts?.tsid || null,
      //_tsstatus: newStatus,
      _conid: this._ts?.conid,
      _startdate: startdate.local().format('yyyy-MM-DD HH:mm:ss'),
      _enddate: enddate.local().format('yyyy-MM-DD HH:mm:ss'),
      _comment: formData.dates[dateIndex].message,
      _distandpark: formData.dates[dateIndex].kmFee || 0,
      _distandparkcomment: formData.dates[dateIndex].kmComment,
      _park: formData.dates[dateIndex].parkFee || 0,
      _parkcomment: formData.dates[dateIndex].parkComment,
      _vignette: formData.dates[dateIndex].vignFee || 0,
      _vignettecomment: formData.dates[dateIndex].vignComment,
      _otherfee: formData.dates[dateIndex].otherAllowance || 0,
      _dayoptions: dayoptions.length ? dayoptions : {},
      _salary: salaryOverride,
      _data: JSON.stringify({
        workhours: formData.workhours || null,
        dailybasedrentalrate1: formData.dates[dateIndex].requestRental1 ? this.rentalFee1 : 0,
        dailybasedrentalrate2: formData.dates[dateIndex].requestRental2 ? this.rentalFee2 : 0,
        dailybasedrentalrate3: formData.dates[dateIndex].requestRental3 ? this.rentalFee3 : 0,
        dailybasedrentalrate4: formData.dates[dateIndex].requestRental4 ? this.rentalFee4 : 0,
        dailybasedrentalrate5: formData.dates[dateIndex].requestRental5 ? this.rentalFee5 : 0,
        dailybasedrentalrate6: formData.dates[dateIndex].requestRental6 ? this.rentalFee6 : 0,
        mealPenalty: this.calcMealPenaltyWhenFromSf(dateIndex),// A kalkulált meal penalty összeg
        mealPenaltyHours: this.datesFA.controls[dateIndex].get('mealPenaltyHours')?.getRawValue(), // Mennyi időt adott meg meal penalty-ra
      })
    }

    return retVal
  }

  private calcMealPenaltyWhenFromSf(dateIndex: number): number  {
    const mealPenaltyRate = this.contractMealPenaltyRate(this.currContract$.value)
    if (!mealPenaltyRate) return 0
    const mealPenaltyHours = this.datesFA.controls[dateIndex].get('mealPenaltyHours')?.getRawValue()
    return mealPenaltyRate * mealPenaltyHours
  }

  private getDayOptions(formData: any, dateIndex: number): DayOptions[] {
    const dayoptions = []
    if (formData.dates[dateIndex].monthlyRentsDue) dayoptions.push(DayOptions.MonthlyRentsDue)
    if (formData.dates[dateIndex].halfDay) dayoptions.push(DayOptions.HalfDay)
    if (formData.dates[dateIndex].travelDay) dayoptions.push(DayOptions.TravelDay)
    if (formData.dates[dateIndex].perdiem) dayoptions.push(DayOptions.PerDiem)
    if (formData.dates[dateIndex].mealPenaltyAsDayOptions) dayoptions.push(DayOptions.MealPenalty)
    if (formData.dates[dateIndex].restDay) dayoptions.push(DayOptions.RestDay)
    return dayoptions
  }

  private getTimes(dateIndex: number): { startdate: moment.Moment, enddate: moment.Moment } {
    const formData = this.form.value
    const fromDate = formData.dates[dateIndex].fromDate
    let startdate = moment(moment(fromDate).format("YYYY-MM-DD") + ' ' + formData.fromTime, 'YYYY-MM-DD HH:mm').utc()
    let enddate = moment(moment(fromDate).format("YYYY-MM-DD") + ' ' + formData.toTime, 'YYYY-MM-DD HH:mm').utc()
    if (moment(formData.toTime, 'HH:mm').isBefore(moment(formData.fromTime, 'HH:mm'))) enddate = moment(enddate).add(1, 'day')
    return { startdate, enddate }
  }
  
  getCheckTimesheetParams(dateIndex: number, aErr: string[]): CheckTimesheetParams | null {
    if (!this.isValid(aErr)) {
      return null
    }
    const formData = this.form.value
    let startdate = moment(moment(formData.dates[dateIndex].fromDate).format("YYYY-MM-DD") + ' ' + formData.fromTime, 'YYYY-MM-DD HH:mm').utc()
    let enddate = moment(moment(formData.dates[dateIndex].fromDate).format("YYYY-MM-DD") + ' ' + formData.toTime, 'YYYY-MM-DD HH:mm').utc()
    if (moment(formData.toTime, 'HH:mm').isBefore(moment(formData.fromTime, 'HH:mm'))) enddate = moment(enddate).add(1, 'day')
    // const dayoptions = []
    // if (formData.halfDay) dayoptions.push(DayOptions.HalfDay)
    // if (formData.travelDay) dayoptions.push(DayOptions.TravelDay)
    // if (formData.perdiem) dayoptions.push(DayOptions.PerDiem)
    let retVal: HandleTimesheetsParams = {
      _tsid: this._ts?.tsid || null,
      _conid: this._ts?.conid,
      _startdate: this.parser.datetimeForSql(startdate),
      _enddate: this.parser.datetimeForSql(enddate) ,
    }

    return retVal
  }

  overlapseChecked(checkTsResult: CheckTs, dateIndex: number): void {
    console.log(checkTsResult);
    if (!this.aOverlapse$[dateIndex]) {
      this.aOverlapse$[dateIndex] = new BehaviorSubject<CheckTs | null>(checkTsResult)
      this.aOverlapseSubscriptioins[dateIndex] = this.aOverlapse$[dateIndex].subscribe(checkTsResult => {
        this.warnHoliday(checkTsResult, dateIndex)
      })
    }
    else this.aOverlapse$[dateIndex].next(checkTsResult)
  }

  private warnHoliday(checkTsResult: CheckTs | null, dateIndex: number): void {
    if (checkTsResult?.daytype === 'holy') {
      const date = moment(this.form.value.dates[dateIndex].fromDate).format(SQL_DATE_FORMAT)
      const msg = `${date} ${this.t7eNotifHolidayWarning}`
      this.notifSvc.confirm({
        message: msg,
        title: this.t7eNotifHolidayWarningTitle,
        cancelButtonText: this.t7eIAmSureButtonText,
        okButtonText: this.t7eDeleteSaveButtonText,
        okCallback: () => {
          this.removeDate(dateIndex)
        },
      })
    }
  }

  private isValid(aErr: string[]): boolean {
    if (!this._ts?.conid) {
      aErr.push('Nincs kiválasztva szerepkör.')
    }
    // push to aErr, if invalid fromTime, toTime, workhours
    const formData = this.form.getRawValue()
    if (!formData.fromTime) aErr.push(this.t7eNotifCannotSaveNoFromTime)
    if (!formData.toTime) aErr.push(this.t7eNotifCannotSaveNoToTime)
    if (!formData.workhours) aErr.push(this.t7eNotifCannotSaveNoWorkhours)
    if (formData.workhours && formData.workhours < 0) aErr.push(this.t7eNotifCannotSaveNegativeWorkhours)
    if (formData.workhours && formData.workhours > 24) aErr.push(this.t7eNotifCannotSaveTooManyWorkhours)
    // is fromTime and endTime in hh:mm format?
    if (formData.fromTime && !moment(formData.fromTime, 'HH:mm').isValid()) aErr.push(this.t7eNotifCannotSaveInvalidFromTime)
    if (formData.toTime && !moment(formData.toTime, 'HH:mm').isValid()) aErr.push(this.t7eNotifCannotSaveInvalidToTime)

    return !aErr.length
  }

  private getFG(formData: TimesheetParsed | null): FormGroup {
    if (this.AUTOFILL_TIMES) formData = this.setDatesIfNew(formData)
    const fillDate = this.AUTOFILL_TIMES || !!formData?.tsid
    return this.fb.group({
      dates: this.fb.array([
       this.getDatesFG(formData)
      ]),
      workhours: [formData?.workhours || 12, [NumericValidator]],
      fromTime: [fillDate ? moment(formData?.dStartDate).format('HH:mm') : null, Validators.required],
      toTime: [fillDate ? moment(formData?.dEndDate).format('HH:mm') : null, Validators.required],
    })
  }

  private getDatesFG(formData: TimesheetParsed | null): FormGroup {
    return this.fb.group({
      fromDate: [formData?.dStartDate || null, Validators.required],
      message: [formData?.comment],
      location: [],
      kmFee: [formData?.distandpark, [NumericValidator]],
      kmComment: [formData?.distandparkcomment],
      parkFee: [formData?.park, [NumericValidator]],
      parkComment: [formData?.parkcomment],
      vignFee: [formData?.vignette, [NumericValidator]],
      vignComment: [formData?.vignettecomment],
      requestRental1: [formData?.f_dailybasedrentalrate1],
      requestRental2: [formData?.f_dailybasedrentalrate2],
      requestRental3: [formData?.f_dailybasedrentalrate3],
      requestRental4: [formData?.f_dailybasedrentalrate4],
      requestRental5: [formData?.f_dailybasedrentalrate5],
      requestRental6: [formData?.f_dailybasedrentalrate6],

      monthlyRentsDue: [this.isMonthlyRentsDue(formData?.dayoptions)],
      halfDay: [this.isHalfDay(formData?.dayoptions)],
      travelDay: [this.isTravelDay(formData?.dayoptions)],
      perdiem: [this.isPerdiem(formData?.dayoptions)],
      restDay: [this.isRestDay(formData?.dayoptions)],
      mealPenalty: [this.isMealPenalty(formData?.dayoptions)],

      // Ha Meal Penalty == firstValueableOtRate
      mealPenaltyAsDayOptions: [this.isMealPenalty(formData?.dayoptions)],

      // Ha MealPenalty == setAtProdDeptSf
      mealPenaltyHours: [formData?.f_mealPenaltyHours || 0],
      mealPenaltyFee: [formData?.f_mealPenalty || 0],

      otherAllowance: [formData?.otherfee, [NumericValidator]],
      salaryOverride: [formData?.salary, [NumericValidator]],
    })
  }

  setDates(dates: Date[] | null): void {
    if (!dates?.length) return
    this.datesFA.clear()
    dates
      .sort((a, b) => moment(a).isBefore(moment(b)) ? -1 : 1)
      .forEach(date => {
      this.datesFA.push(this.getDatesFG({ dStartDate: date }))
    })
  }

  addDate(): void {
    const prevLastIndex = this.datesFA.controls.length - 1
    const prevDate = this.datesFA.controls[prevLastIndex].get('fromDate')?.value
    const newDate = moment(prevDate).add(1, 'day').toDate()
    this.datesFA.push(this.getDatesFG({ dStartDate: newDate }))
    this.checkTs(prevLastIndex + 1)
    this.emitSelectedDates()
  }
  removeDate(index: number): void {
    this.datesFA.removeAt(index)
    this.removeAdjacentCheck(index)
    this.emitSelectedDates()
  }
  removeAdjacentCheck(index: number): void {
    this.adjacentTsParams$[index]?.complete()
    this.adjacentTsParams$.splice(index, 1)
    this.aOverlapse$[index]?.complete()
    this.aOverlapse$.splice(index, 1)
    this.aOverlapseSubscriptioins[index]?.unsubscribe()
  }

  private emitSelectedDates(): void {
    const dates = this.datesFA.value
      .map((x: any) => x.fromDate instanceof Date ? x.fromDate : x.fromDate.toDate())
    this.selectedDateChange.emit(dates)
  }

  showHistory(e: MouseEvent, propcodeOrColname: string, isFlex: boolean) {
    const tsid = this._ts?.tsid
    //console.log('Előzmények a ts-hez: ', tsid);
    if (!tsid) return
    this.history =
    {
      tableid: FlexTables.timesheets,
      id: tsid,
      propcode: isFlex ? propcodeOrColname : undefined,
      colname: isFlex ? undefined : propcodeOrColname,
    }
  }

  isMonthlyRentsDue(dayOptions: DayOptions[] | DayOptions | null | undefined): boolean {
    if (!dayOptions) return false
    if (Array.isArray(dayOptions)) {
      return dayOptions.some(x => x == DayOptions.MonthlyRentsDue)
    }
    return dayOptions == DayOptions.MonthlyRentsDue
  }

  isHalfDay(dayOptions: DayOptions[] | DayOptions | null | undefined): boolean {
    if (!dayOptions) return false
    if (Array.isArray(dayOptions)) {
      return dayOptions.some(x => x == DayOptions.HalfDay)
    }
    return dayOptions == DayOptions.HalfDay
  }

  isTravelDay(dayOptions: DayOptions[] | DayOptions | null | undefined): boolean {
    if (!dayOptions) return false
    if (Array.isArray(dayOptions)) {
      return dayOptions.some(x => x == DayOptions.TravelDay)
    }
    return dayOptions == DayOptions.TravelDay
  }
  isPerdiem(dayOptions: DayOptions[] | DayOptions | null | undefined) {
    if (Array.isArray(dayOptions)) {
      return dayOptions.some(x => x == DayOptions.PerDiem)
    }
    return dayOptions == DayOptions.PerDiem
  }
  isRestDay(dayOptions: DayOptions[] | DayOptions | null | undefined) {
    if (Array.isArray(dayOptions)) {
      return dayOptions.some(x => x == DayOptions.RestDay)
    }
    return dayOptions == DayOptions.RestDay
  }
  isMealPenalty(dayOptions: DayOptions[] | DayOptions | null | undefined) {
    if (Array.isArray(dayOptions)) {
      return dayOptions.some(x => x == DayOptions.MealPenalty)
    }
    return dayOptions == DayOptions.MealPenalty
  }

  warnSalaryOverride(triggerField: 'halfDay' | 'travelDay' | 'restDay'): void {
    if (!this.canEdit(this._ts) || !this._ts?.tsid) return
    const salaryOverride = this.datesFA.getRawValue()?.[0]?.salaryOverride
    if (!salaryOverride) return
    const warnSalaries = this.warnSalaryOverride$.value
    if (!warnSalaries.includes(triggerField)) {
      this.warnSalaryOverride$.next([...warnSalaries, triggerField])
    }
  }

  getDateForSectionTitle(date: Date): string {
    return moment(date).locale(this.lang).format('yyyy-MM-DD dddd')
  }
  trackByControlname(index: number, item: { controlName: string }): string {
    return item.controlName
  }
  trackByLocId = (index: number, item: any) => item.locId

  private scrollFormIntoView(): void {
    setTimeout(() => {
      if (this.layout === 'grid-form' || this._ts?.tsid) return
      document.getElementById('workhours-and-times')?.scrollIntoView({behavior: 'smooth', block: "start", inline: "nearest"})
    }, 0);
  }


  /** T7E */
  // page title
  t7ePageTitle = this.t7e.getTranslation('app-edit-timesheet', 'page-title', 'title', 'Timesheet szerkesztése')
  t7eProdmgrMsg = this.t7e.getTranslation('app-edit-timesheet', 'page-warning', 'title', 'A gyártásvezető üzenete')
  t7eHourSuffix = this.t7e.getTranslation('form', 'hour-suffix', 'innerText', null)

  // Btn texts and titles
  t7eBtnDeleteAlreadyDeletedTitle = this.t7e.getTranslation('app-edit-timesheet', 'btn-delete-already-deleted', 'title', 'Már törölve')
  t7eBtnSubmitNoContractTitle = this.t7e.getTranslation('app-edit-timesheet', 'can-submit-no--contract', 'title', 'Válassz szerepkört!')
  t7eBtnSubmitFormInvalidTitle = this.t7e.getTranslation('app-edit-timesheet', 'can-submit-form-invalid', 'title', 'Az űrlap nincs helyesen kitöltve')
  t7eBtnSubmitFormNotSavedTitle = this.t7e.getTranslation('app-edit-timesheet', 'can-submit-form-not-saved', 'title', 'Előbb mentsd el a változtatásokat')
  t7eBtnSubmitSavingTitle = this.t7e.getTranslation('app-edit-timesheet', 'can-submit-saving', 'title', 'Mentés folyamatban')
  t7eBtnSubmitOverlapTitle = this.t7e.getTranslation('app-edit-timesheet', 'can-submit-overlap', 'title', 'Átfedés egy másik timesheettel')
  t7eBtnSubmitInvAcceptedTitle = this.t7e.getTranslation('app-edit-timesheet', 'can-submit-inv-accepted', 'title', 'Számla már befogadva')
  t7eBtnSubmitAttGeneratingTitle = this.t7e.getTranslation('app-edit-timesheet', 'can-submit-att-generating', 'title', 'Számlamelléklet épp generálás alatt van')
  t7eBtnSubmitAlreadySubmittedTitle = this.t7e.getTranslation('app-edit-timesheet', 'can-submit-already-submitted', 'title', 'Már beküldve')
  t7eBtnSubmitFutureTitle = this.t7e.getTranslation('app-edit-timesheet', 'can-submit-future', 'title', 'Jövőbeli timesheet nem küldhető be')
  t7eBtnSubmitNoPermissionTitle = this.t7e.getTranslation('app-edit-timesheet', 'can-submit-no-permission', 'title', 'Nincs jogosultságod')
  t7eBtnSubmitNotWaitingApprovalTitle = this.t7e.getTranslation('app-edit-timesheet', 'can-submit-not-waiting-approval', 'title', 'Nem vár jóváhagyásra')
  t7eBtnSaveDebugTitle = this.t7e.getTranslation('app-edit-timesheet', 'can-save-debug', 'title', 'DEBUG bekapcsolva')
  t7eBtnSaveSavingTitle = this.t7e.getTranslation('app-edit-timesheet', 'can-save-saving', 'title', 'Mentés folyamatban...')
  t7eBtnSaveNoChangeTitle = this.t7e.getTranslation('app-edit-timesheet', 'can-save-no-change', 'title', 'Nincs változtatás az űrlapon')
  t7eBtnSaveFormInvalidTitle = this.t7e.getTranslation('app-edit-timesheet', 'can-save-form-invalid', 'title', 'Kitöltési hibák')
  t7eBtnSaveTitle = this.t7e.getTranslation('app-edit-timesheet', 'can-save', 'title', 'Mentés')
  t7eBtnDisabledTsNotEditableTitle = this.t7e.getTranslation('app-edit-timesheet', 'btn-disabled-ts-not-editable', 'title', 'Letiltott timesheet nem szerkeszthető')
  t7eDisabledTsNotEditableDescr = this.t7e.getTranslation('app-edit-timesheet', 'btn-disabled-ts-not-editable', 'descr', 'Kérd a gyártásvezetőt, hogy hagyja jóvá vagy engedélyezze a szerkesztést')

  // notif-msgs
  t7eNotifUnknownTs = this.t7e.getTranslation('app-edit-timesheet', 'notif-msg', 'unknown-ts', 'Ismeretlen Timesheet')
  t7eNotifMultipleTsFromRouteError = this.t7e.getTranslation('app-edit-timesheet', 'notif-msg', 'multiple-ts-from-route', 'Timesheet betöltés hiba')
  t7eNotifTsLoadError = this.t7e.getTranslation('app-edit-timesheet', 'notif-msg', 'ts-load-error', 'Hiba történt a Timesheet betöltése közben')
  t7eNotifSubmitting = this.t7e.getTranslation('app-edit-timesheet', 'notif-msg', 'submitting', 'Küldés...')
  t7eNotifDbError = this.t7e.getTranslation('app-edit-timesheet', 'notif-msg', 'db-error', 'Adatbázis hiba. Talán nem sikerült a mentés')
  t7eNotifCannotSaveError = this.t7e.getTranslation('app-edit-timesheet', 'notif-msg', 'cannot-save-error', 'Nem menthető: ')
  t7eNotifSaveSuccess = this.t7e.getTranslation('app-edit-timesheet', 'notif-msg', 'save-success', 'A mentés sikerült')
  t7eNotifSaveError = this.t7e.getTranslation('app-edit-timesheet', 'notif-msg', 'save-error', 'Sikertelen mentés')
  t7eNotifNthTsCannotSaveError = this.t7e.getTranslation('app-edit-timesheet', 'notif-msg', 'nth-ts-cannot-save-error', '. timesheet nem menthető: ')
  t7eNotifSubmitSuccess = this.t7e.getTranslation('app-edit-timesheet', 'notif-msg', 'submit-success', 'Timesheet beküldve.')
  t7eNotifSaveErrorColon = this.t7e.getTranslation('app-edit-timesheet', 'notif-msg', 'save-error-colon', 'Sikertelen mentés: ')
  t7eNotifStatusChangeSuccess = this.t7e.getTranslation('app-edit-timesheet', 'notif-msg', 'status-change-success', 'Státusz módosítás sikerült')
  t7eNotifStatusChangeError = this.t7e.getTranslation('app-edit-timesheet', 'notif-msg', 'status-change-error', 'Sikertelen státusz módosítás')
  t7eNotifCannotSaveNoContract = this.t7e.getTranslation('app-edit-timesheet', 'notif-msg', 'cannot-save-no-contract', 'Nincs kiválasztva szerepkör.')
  t7eNotifCannotSaveNoFromTime = this.t7e.getTranslation('app-edit-timesheet', 'notif-msg', 'cannot-save-no-from-time', 'Nincs megadva kezdési idő.')
  t7eNotifCannotSaveNoToTime = this.t7e.getTranslation('app-edit-timesheet', 'notif-msg', 'cannot-save-no-to-time', 'Nincs megadva befejezési idő.')
  t7eNotifCannotSaveNoWorkhours = this.t7e.getTranslation('app-edit-timesheet', 'notif-msg', 'cannot-save-no-workhours', 'Nincs megadva munkaóra.')
  t7eNotifCannotSaveNegativeWorkhours = this.t7e.getTranslation('app-edit-timesheet', 'notif-msg', 'cannot-save-negative-workhours', 'A munkaóra nem lehet negatív.')
  t7eNotifCannotSaveTooManyWorkhours = this.t7e.getTranslation('app-edit-timesheet', 'notif-msg', 'cannot-save-too-many-workhours', 'A munkaóra nem lehet több, mint 24 óra.')
  t7eNotifCannotSaveInvalidFromTime = this.t7e.getTranslation('app-edit-timesheet', 'notif-msg', 'cannot-save-invalid-from-time', 'A kezdési idő nem megfelelő formátumú.')
  t7eNotifCannotSaveInvalidToTime = this.t7e.getTranslation('app-edit-timesheet', 'notif-msg', 'cannot-save-invalid-to-time', 'A befejezési idő nem megfelelő formátumú.')
  t7eNotifHolidayWarning = this.t7e.getTranslation('app-edit-timesheet', 'notif-msg', 'holiday-warning', 'ünnepnap. Biztos, hogy dolgoztál?')
  t7eNotifHolidayWarningTitle = this.t7e.getTranslation('app-edit-timesheet', 'notif-msg', 'holiday-warning-title', 'Ünnepnap')
  t7eIAmSureButtonText = this.t7e.getTranslation('app-edit-timesheet', 'notif-msg', 'i-am-sure-button-text', 'Biztos')
  t7eLocationConfirmEligibleText = this.t7e.getTranslation('app-edit-timesheet', 'location-confirm-eligible', 'text', null, null, 'Csak akkor jelöld be, ha SAJÁT AUTÓVAL mentél, és jár üzemanyag-térítés')

  // sendBack, delete
  t7eSendBackAreYouSureTitle = this.t7e.getTranslation('app-edit-timesheet', 'send-back', 'are-you-sure', 'Valóban visszaküldöd? Miért?')
  t7eSendBackSaveButtonText = this.t7e.getTranslation('app-edit-timesheet', 'send-back', 'save-button-text', 'Visszaküldés')
  t7eDeleteAreYouSureTitle = this.t7e.getTranslation('app-edit-timesheet', 'delete', 'are-you-sure', 'Biztos törlöd?')
  t7eDeleteSaveButtonText = this.t7e.getTranslation('app-edit-timesheet', 'delete', 'save-button-text', 'Törlés')

  t7eCheckAgeTooOldTitle = this.t7e.getTranslation('app-edit-timesheet', 'check-age', 'too-old-title', null, null, 'Túl régi timesheet')
  t7eCheckAgeTooOld = this.t7e.getTranslation('app-edit-timesheet', 'check-age', 'too-old', null, null, 'Egy hétnél régebbi timesheet nem küldhető be. Fordulj a gyártásvezetőhöz egyedi elbíráslásért')
  t7eCheckAgeTooOldNoOt = this.t7e.getTranslation('app-edit-timesheet', 'check-age', 'too-old-no-ot',null,null, 'Egy hétnél régebbi timesheet nem küldhető be. Fordulj a gyártásvezetőhöz egyedi elbíráslásért')
  t7eSubmitAsDisabled = this.t7e.getTranslation('app-edit-timesheet', 'check-age', 'submit-as-disabled', null, null, 'Mentés így, egyeztetek a gyártásvezetővel')
  t7eModifyTimes = this.t7e.getTranslation('app-edit-timesheet', 'check-age', 'modify-times', null, null, 'Időpontok módosítása')

  async test(x: any) {
    if (x == 'loading') {
      this.prodSvc.currencyExchangeRatesLoading$.next(true)
    }
    if (x === 'error') {
      this.prodSvc.currencyExchangeRatesError$.next('áf hiba')
    }
    if (x === 'ok') {
      this.prodSvc.currencyExchangeRatesLoading$.next(false)
      this.prodSvc.currencyExchangeRatesError$.next(false)

    }
    const params: CalcSalaryParams = {
      contractId: 634,
      startDate: new Date('2023-10-10 10:00'),
      endDate: new Date('2023-10-10 22:00'),
      workingHours: 10,
      dayOptions: [],
      otRates: [999],
      otStep: 1, // mindegy mennyi, csak ne 0 legyen
      gracePeriod: 15,
    }

    this.tsSvc.getCalculatedFeesForDay(params)
  }
}

