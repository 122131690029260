<div class="ts-loading" *ngIf="isLoading">
    <app-page-loading></app-page-loading>
</div>

<!-- <button (click)="test()">test</button> -->

<section class="selected-contract mb-1" *ngIf="showContract && (userContracts$ | async)?.length != 1">
    <h1 t7e="selected-contract">Selected Position</h1>
    <app-select-contract [activeContract$]="currContract$"
        [selectable]="!ts?.tsid" [showAddButton]="false" [hideStatus]="false" *ngIf="isOwnTs" 
        class="section-box">
    </app-select-contract>
</section>

<section class="selected-contract mb-1" *ngIf="showContract && !isOwnTs">
    <app-user-card [user]="getUserById(ts?.usid)"></app-user-card>
</section>
<section class="selected-contract mb-1" *ngIf="showContract && !isOwnTs">
    <app-contract [contract$]="currContract$"></app-contract>
</section>

<!-- <div class="ts-status mb-1 mt-1" *ngIf="(showStatus && !isLoading); else noStatus"> -->
<div class="ts-status mb-1 mt-1" *ngIf="(showStatus && !isLoading) || showHistoryButtons || statusSaveComment; else noStatus" [ngClass]="showHistoryButtons ? ' show-history' : ''">
    <app-status [statusName]="statusName" 
        [statusClass]="statusClass" 
        [saveComment]="statusSaveComment || null" 
        [statusOf]="statusOf || null">
    </app-status>
    <button mat-icon-button (click)="showHistory($event, 'tsstatus', false)" type="button" class="has-history"
        tabindex="-1">
        <mat-icon>history</mat-icon>
    </button>
</div>
<ng-template #noStatus>
    <app-page-warning *ngIf="statusSaveComment" 
        [title]="t7eProdmgrMsg" [description]="statusSaveComment"
        [cssClass]="'warning'" class="d-flex justify-content-center">
    </app-page-warning>
</ng-template>

<app-page-warning *ngIf="isDisabled" [title]="t7eBtnDisabledTsNotEditableTitle" 
    [description]="t7eDisabledTsNotEditableDescr" [cssClass]="'warning'" class="d-flex justify-content-center">
</app-page-warning>

<div *ngIf="isDebug">
    {{ dates | json }}
    <hr>
    {{ ts | json }}
    <hr>
    {{ currContract$ | async | json }}
    <hr>
    {{ form.value | json }}
    <hr>
</div>

<form [formGroup]="form" *ngIf="!isLoading && (currContract$ | async)"
    [ngClass]="layout + (showHistoryButtons ? ' show-history' : '')" kendoPopoverContainer filter=".has-history"
    [popover]="popHistory" [showOn]="'click'">

    <div class="form-field date">
        <div class="date-selectors" formArrayName="dates">

            <div *ngFor="let day of datesFA.controls; let i=index;" [formGroupName]="i">
                <div class="input-wrapper date-input-wrapper">

                    <div class="date-form-field-and-adjacent-checker">
                        <mat-form-field class="mx-3" appearance="outline">
                            <mat-label t7e="date-label">Dátum</mat-label>
                            <input matInput [matDatepicker]="picker" formControlName="fromDate" [min]="minDate"
                                [max]="maxDate" (dateChange)="onDateFGChange(i)">
                            <mat-hint t7e="date-hint">Melyik napra viszed be az adatokat?</mat-hint>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                        <app-adjacent-timesheets *ngIf="!ts?.tsid && adjacentTsParams$[i]" [params$]="adjacentTsParams$[i]"
                            (overlapseChecked)="overlapseChecked($event, i)">
                        </app-adjacent-timesheets>
                    </div>
                    <!-- {{ (aOverlapse$[i] | async) | json}}??????? -->
                    <span *ngIf="isDebug" style="background-color: pink; padding: 1rem; position: absolute;margin-top: 6rem;">
                        {{ adjacentTsParams$[i] | async | json }}
                    </span>
                    <!-- {{ts?.tsid }} -->

                    <div class="remove-date" *ngIf="i > 0">
                        <button mat-icon-button (click)="removeDate(i)" type="button">
                            <mat-icon>delete_outline</mat-icon>
                        </button>
                    </div>
                </div>

            </div>
            <div class="mb-2" *ngIf="canAddDate">
                <button mat-raised-button *ngIf="!isEdit" type="button" (click)="addDate()">
                    <mat-icon>add</mat-icon>
                </button>
            </div>
        </div>
    </div>

    <div class="form-field workhours-and-times" id="workhours-and-times">
        <div class="workhours">
            <mat-form-field class="" appearance="outline">
                <mat-label t7e="workhours-label">Óraszám</mat-label>
                <mat-select formControlName="workhours" name="item" required="">
                    <mat-option [value]="10" *ngIf="true || isProdMgr">10</mat-option>
                    <mat-option [value]="10.5" *ngIf="true || isProdMgr">10,5</mat-option>
                    <mat-option [value]="12">12</mat-option>
                </mat-select>
                <mat-error *ngIf="form?.hasError('required', 'workhours')" t7e="required-error">
                    A mező kitöltése kötelező
                </mat-error>
            </mat-form-field>
            <button mat-icon-button (click)="showHistory($event, 'workhours', true)" type="button" class="has-history"
                tabindex="-1">
                <mat-icon>history</mat-icon>
            </button>
        </div>
        <div class="times" *ngIf="tsWorkhours">
            <mat-form-field appearance="outline">
                <mat-label t7e="starttime-label">Hány órakor kezdtél?</mat-label>
                <input matInput id="fromTime" type="time-24" formControlName="fromTime" (change)="checkTs()">
            </mat-form-field>
            <button mat-icon-button (click)="showHistory($event, 'startdate', false)" type="button" class="has-history"
                tabindex="-1">
                <mat-icon>history</mat-icon>
            </button>
            <mat-form-field class="ml-1" appearance="outline">
                <mat-label t7e="endtime-label">Hány órakor végeztél?</mat-label>
                <input matInput type="time-24" formControlName="toTime" (change)="checkTs()">
            </mat-form-field>
            <button mat-icon-button (click)="showHistory($event, 'enddate', false)" type="button" class="has-history"
                tabindex="-1">
                <mat-icon>history</mat-icon>
            </button>
        </div>
    </div>

    <ng-container formArrayName="dates" *ngIf="tsWorkhours">
        <ng-container *ngFor="let day of datesFA.controls; let i=index;" [formGroupName]="i">
            <app-section-title *ngIf="datesFA.length > 1"
                [title]="getDateForSectionTitle(day.value.fromDate)"></app-section-title>
            <div class="form-field msgToProdMgr">
                <mat-form-field class="form-field span-2" appearance="outline">
                    <mat-label t7e="remark-to-prodmgr">Üzenet a gyártásvezetőnek</mat-label>
                    <textarea matNativeControl formControlName="message" rows="3"></textarea>
                </mat-form-field>
            </div>

            <section class="form-field km">
                <mat-form-field class="location-form-field" appearance="outline" *ngIf="(isExchangeRateReady$ | async)">
                    <mat-label t7e="location-label">Forgatási helyszín</mat-label>
                    <mat-select formControlName="location" (valueChange)="onLocationChange(i, $event)">
                        <mat-option [value]="">Egyik sem</mat-option>
                        <ng-container *ngFor="let loc of locations$ | async; trackBy:trackByLocId">
                            <mat-option [value]="loc.locCode">{{loc.locName}}</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
                <div class="exchange-rate-loading" *ngIf="!(isExchangeRateReady$ | async) && (exchangeRatesLoading$ | async)">
                    <mat-spinner [diameter]="25"></mat-spinner>
                    {{ 'exchange-rate-loading' | t7e: 'Az árfolyam töltödik' }}
                </div>
                <div class="exchange-rate-error" *ngIf="!(isExchangeRateReady$ | async) && (exchangeRatesError$ | async)">
                    {{ 'exchange-rate-error' | t7e: 'Árfolyam hiba' }}
                </div>
                <button *ngIf="isDebug" (click)="test('loading')">loading</button>
                <button *ngIf="isDebug" (click)="test('error')">error</button>
                <button *ngIf="isDebug" (click)="test('ok')">ok</button>
                <div *ngIf="isDebug">location: {{ !!datesFA.controls[i].get('location')?.getRawValue() | json }}</div>
                <div *ngIf="isDebug">kmFee dirty: {{ datesFA.controls[i].get('kmFee')?.dirty | json }}</div>
                <div *ngIf="isDebug">kmComment dirty: {{ datesFA.controls[i].get('kmComment')?.dirty | json }}</div>

                <mat-form-field class="fee-amount" appearance="outline">
                    <mat-label t7e="km-fee-label">Km díj</mat-label>
                    <input matNativeControl formControlName="kmFee" (change)="onKmOrCommentChange(i)">
                    <span matSuffix>{{ currency }}</span>
                    <mat-hint t7e="km-fee-hint">Igényelt összeg</mat-hint>
                    <mat-error *ngIf="getDateFG(i)?.hasError('numeric', 'kmFee')" t7e="not-a-number-error">
                        Érvénytelen szám
                    </mat-error>
                </mat-form-field>
                <button mat-icon-button (click)="showHistory($event, 'distandpark', false)" type="button"
                    class="has-history" tabindex="-1">
                    <mat-icon>history</mat-icon>
                </button>

                <mat-form-field class="fee-reason" appearance="outline">
                    <mat-label t7e="km-reason-label">Km indoklás</mat-label>
                    <input matNativeControl formControlName="kmComment" type="text" (change)="onKmOrCommentChange(i)">
                    <mat-hint t7e="km-reason-hint">Hány km-t kívánsz elszámolni?</mat-hint>
                </mat-form-field>
                <button mat-icon-button (click)="showHistory($event, 'distandparkcomment', false)" type="button"
                    class="has-history" tabindex="-1">
                    <mat-icon>history</mat-icon>
                </button>
            </section>

            <section class="form-field parking">
                <mat-form-field class="fee-amount" appearance="outline">
                    <mat-label t7e="parking-fee-label">Parkolás díj</mat-label>
                    <input matNativeControl formControlName="parkFee">
                    <span matSuffix>{{ currency }}</span>
                    <mat-hint t7e="parking-fee-hint">Igényelt összeg</mat-hint>
                    <mat-error *ngIf="getDateFG(i)?.hasError('numeric', 'parkFee')" t7e="not-a-number-error">
                        Érvénytelen szám
                    </mat-error>
                </mat-form-field>
                <button mat-icon-button (click)="showHistory($event, 'park', false)" type="button" class="has-history"
                    tabindex="-1">
                    <mat-icon>history</mat-icon>
                </button>
                <mat-form-field class="fee-reason" appearance="outline">
                    <mat-label t7e="parking-reasone-label">Parkolás indoklás</mat-label>
                    <input matNativeControl formControlName="parkComment" type="text">
                    <mat-hint t7e="parking-reason-hint">Hol parkoltál, és miért?</mat-hint>
                </mat-form-field>
                <button mat-icon-button (click)="showHistory($event, 'parkcomment', false)" type="button"
                    class="has-history" tabindex="-1">
                    <mat-icon>history</mat-icon>
                </button>
            </section>

            <section class="form-field vignette">
                <mat-form-field class="fee-amount" appearance="outline">
                    <mat-label t7e="vignette-fee-label">Matrica díj</mat-label>
                    <input matNativeControl formControlName="vignFee">
                    <mat-hint t7e="vignette-fee-hint">Igényelt összeg</mat-hint>
                    <span matSuffix>{{ currency }}</span>
                    <mat-error *ngIf="getDateFG(i)?.hasError('numeric', 'vignFee')" t7e="not-a-number-error">
                        Érvénytelen szám
                    </mat-error>
                </mat-form-field>
                <button mat-icon-button (click)="showHistory($event, 'vignette', false)" type="button"
                    class="has-history" tabindex="-1">
                    <mat-icon>history</mat-icon>
                </button>
                <mat-form-field class="fee-reason" appearance="outline">
                    <mat-label t7e="vignette-reasone-label">Matrica típusa</mat-label>
                    <input matNativeControl formControlName="vignComment" type="text">
                </mat-form-field>
                <button mat-icon-button (click)="showHistory($event, 'vignettecomment', false)" type="button"
                    class="has-history" tabindex="-1">
                    <mat-icon>history</mat-icon>
                </button>
            </section>

            <section class="form-field rentals">
                <div *ngIf="rentalFee1">
                    <mat-checkbox formControlName="requestRental1">
                       {{ rentalName1 }} <span *ngIf="isMoreThanCrew || !hasInvoiceContact">({{ rentalFee1 }} {{ currency }})</span>
                    </mat-checkbox>
                    <button mat-icon-button (click)="showHistory($event, 'f_dailybasedrentalrate1', false)" type="button" class="has-history"
                        tabindex="-1">
                        <mat-icon>history</mat-icon>
                    </button>
                </div>
                <div *ngIf="rentalFee2">
                    <mat-checkbox formControlName="requestRental2">
                        {{ rentalName2 }} <span *ngIf="isMoreThanCrew || !hasInvoiceContact">({{ rentalFee2 }} {{ currency }})</span>
                    </mat-checkbox>
                    <button mat-icon-button (click)="showHistory($event, 'f_dailybasedrentalrate2', false)" type="button"
                        class="has-history" tabindex="-1">
                        <mat-icon>history</mat-icon>
                    </button>
                </div>
                <div *ngIf="rentalFee3">
                    <mat-checkbox formControlName="requestRental3">
                        {{ rentalName3 }} <span *ngIf="isMoreThanCrew || !hasInvoiceContact">({{ rentalFee3 }} {{ currency }})</span>
                    </mat-checkbox>
                    <button mat-icon-button (click)="showHistory($event, 'f_dailybasedrentalrate3', false)" type="button"
                        class="has-history" tabindex="-1">
                        <mat-icon>history</mat-icon>
                    </button>
                </div>
                <div *ngIf="rentalFee4">
                    <mat-checkbox formControlName="requestRental4">
                        {{ rentalName4 }} <span *ngIf="isMoreThanCrew || !hasInvoiceContact">({{ rentalFee4 }} {{ currency }})</span>
                    </mat-checkbox>
                    <button mat-icon-button (click)="showHistory($event, 'f_dailybasedrentalrate4', false)" type="button"
                        class="has-history" tabindex="-1">
                        <mat-icon>history</mat-icon>
                    </button>
                </div>
                <div *ngIf="rentalFee5">
                    <mat-checkbox formControlName="requestRental5">
                        {{ rentalName5 }} <span *ngIf="isMoreThanCrew || !hasInvoiceContact">({{ rentalFee5 }} {{ currency }})</span>
                    </mat-checkbox>
                    <button mat-icon-button (click)="showHistory($event, 'f_dailybasedrentalrate5', false)" type="button"
                        class="has-history" tabindex="-1">
                        <mat-icon>history</mat-icon>
                    </button>
                </div>
                <div *ngIf="rentalFee6">
                    <mat-checkbox formControlName="requestRental6">
                        {{ rentalName6 }} <span *ngIf="isMoreThanCrew || !hasInvoiceContact">({{ rentalFee6 }} {{ currency }})</span>
                    </mat-checkbox>
                    <button mat-icon-button (click)="showHistory($event, 'f_dailybasedrentalrate6', false)" type="button"
                        class="has-history" tabindex="-1">
                        <mat-icon>history</mat-icon>
                    </button>
                </div>
            </section>

            <section class="form-field travelday">
                <div *ngIf="hasMonthlyRents">
                    <mat-checkbox formControlName="monthlyRentsDue">
                        <span t7e="monthly-rent-due-label">Havi bérleti díj esedékes</span>
                    </mat-checkbox>
                    <button mat-icon-button (click)="showHistory($event, 'dayoptions', false)" type="button" 
                        class="has-history" tabindex="-1">
                        <mat-icon>history</mat-icon>
                    </button>
                </div>
                <div>
                    <mat-checkbox formControlName="halfDay" (click)="warnSalaryOverride('halfDay')">
                        <span t7e="half-day-label">Fél nap</span>
                        <span *ngIf="(warnSalaryOverride$ | async)?.includes('halfDay')" class="warn-salary-override-icon-wrapper">
                            <mat-icon [title]="'Ezzel a napidíjat nem módosítottad. Azt módosítsd kézzel, ha szükséges'">warning</mat-icon>
                        </span>
                    </mat-checkbox>
                    <button mat-icon-button (click)="showHistory($event, 'dayoptions', false)" type="button" 
                        class="has-history" tabindex="-1">
                        <mat-icon>history</mat-icon>
                    </button>
                </div>
                <div>
                    <mat-checkbox formControlName="travelDay" (click)="warnSalaryOverride('travelDay')">
                        <span t7e="travel-day-label">Utazó nap</span>
                        <span *ngIf="(warnSalaryOverride$ | async)?.includes('travelDay')" class="warn-salary-override-icon-wrapper">
                            <mat-icon [title]="'Ezzel a napidíjat nem módosítottad. Azt módosítsd kézzel, ha szükséges'">warning</mat-icon>
                        </span>
                    </mat-checkbox>
                    <button mat-icon-button (click)="showHistory($event, 'dayoptions', false)" type="button"
                        class="has-history" tabindex="-1">
                        <mat-icon>history</mat-icon>
                    </button>
                </div>
                <div *ngIf="ALLOW_PERDIEM || isMoreThanDeptHead || hasPerdiem(i)">
                    <mat-checkbox formControlName="perdiem">
                        <span t7e="perdiem-label">Per Diem</span>
                    </mat-checkbox>
                    <button mat-icon-button (click)="showHistory($event, 'dayoptions', false)" type="button"
                        class="has-history" tabindex="-1">
                        <mat-icon>history</mat-icon>
                    </button>
                </div>

                <div>
                    <ng-container *ngIf="ALLOW_REST_DAY">
                        <mat-checkbox formControlName="restDay" (click)="warnSalaryOverride('restDay')">
                            <span t7e="restday-label">Pihenőnap</span>
                        </mat-checkbox>
                        <button mat-icon-button (click)="showHistory($event, 'dayoptions', false)" type="button" class="has-history"
                            tabindex="-1">
                            <mat-icon>history</mat-icon>
                        </button>
                    </ng-container>
                </div>
                
                <ng-container [ngSwitch]="MEAL_PENALTY">
                <ng-container *ngIf="contractHasMealPenalty">
                    <div *ngSwitchCase="'firstValueableOtRate'">
                        <mat-checkbox formControlName="mealPenaltyAsDayOptions">
                            <span>Meal Penalty</span>
                            <span *ngIf="isMoreThanCrew"> - {{mealPenaltyRate}} {{ currency }}</span>
                        </mat-checkbox>
                        <button mat-icon-button (click)="showHistory($event, 'dayoptions', false)" type="button" class="has-history"
                            tabindex="-1">
                            <mat-icon>history</mat-icon>
                        </button>
                    </div>

                    <ng-container *ngSwitchCase="'setAtProdDeptSf'">
                        <mat-form-field class="meal-penalty-form-field" appearance="outline">
                            <mat-label>MP</mat-label>
                            <mat-select formControlName="mealPenaltyHours">
                                <mat-option [value]="0">{{ 'none' | t7e: 'Nincs' }}</mat-option>
                                <mat-option [value]="0.25">1/4 {{ t7eHourSuffix }}</mat-option>
                                <mat-option [value]="0.5">1/2{{ t7eHourSuffix }}</mat-option>
                                <mat-option [value]="0.75">3/4{{ t7eHourSuffix }}</mat-option>
                                <mat-option [value]="1">1{{ t7eHourSuffix }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div *ngIf="isDebug" style="position: absolute;">
                            mealPenaltyHours: {{ datesFA.controls[i].get('mealPenaltyHours')?.getRawValue() }};
                            mealPenaltyFee: {{ datesFA.controls[i].get('mealPenaltyFee')?.getRawValue() }};
                            mealPenaltyRate: {{ contractMealPenaltyRate(currContract$ | async) }}
                        </div>
                    </ng-container>

                    <ng-container *ngSwitchDefault>
                        <!-- Szándékosan üres -->
                    </ng-container>
                </ng-container>
                </ng-container>                
            </section>



            <section class="form-field otherAllowence" *ngIf="(isMoreThanCrew || !hasInvoiceContact) && (!isOnlyDeptAdmin || isOwnTs)">
                <mat-form-field class="" appearance="outline">
                    <mat-label t7e="other-allowance-label">Other Allowance</mat-label>
                    <input matNativeControl formControlName="otherAllowance">
                    <mat-hint t7e="other-allowance-hint">Egyéb szerződésen felüli teljesítés</mat-hint>
                    <span matSuffix>{{ currency }}</span>
                    <mat-error *ngIf="getDateFG(i)?.hasError('numeric', 'otherAllowance')" t7e="not-a-number-error">
                        Érvénytelen szám
                    </mat-error>
                </mat-form-field>
                <button mat-icon-button (click)="showHistory($event, 'otherfee', false)" type="button"
                    class="has-history" tabindex="-1">
                    <mat-icon>history</mat-icon>
                </button>
            </section>

            <section class="form-field salaryOverride" [ngClass]="{'hidden': !isProdMgr && !DISPLAY_SALARY_OVERRIDE_FIELD}"
                *ngIf="(isMoreThanCrew || (isEdit || isProdMgr) && !hasInvoiceContact) && (!isOnlyDeptAdmin || isOwnTs)">
                <mat-form-field class="" appearance="outline">
                    <mat-label t7e="salary-override-label">Salary Override</mat-label>
                    <input matNativeControl formControlName="salaryOverride">
                    <mat-hint t7e="salary-override-hint">If the calculated salary doesn't apply</mat-hint>
                    <span matPrefix><mat-icon *ngIf="(warnSalaryOverride$ | async)?.length" 
                            class="warn-salary-override-icon-wrapper" 
                            [title]="'Ezzel a napidíjat nem módosítottad. Azt módosítsd kézzel, ha szükséges'">warning</mat-icon>
                    </span>
                    <span matSuffix>{{ currency }}</span>
                    <mat-error *ngIf="getDateFG(i)?.hasError('numeric', 'salaryOverride')" t7e="not-a-number-error">
                        Érvénytelen szám
                    </mat-error>
                </mat-form-field>
                <button mat-icon-button (click)="showHistory($event, 'salary', false)" type="button" class="has-history"
                    tabindex="-1">
                    <mat-icon>history</mat-icon>
                </button>
            </section>

            <section class="form-field calc" *ngIf="ts?.tsid && (!isOnlyDeptAdmin || isOwnTs)">
                <div class="calc-tabs"*ngIf="isEdit">
                    <div class="calc-tab" t7e="calc-fees-title" 
                        [class.selected]="selectedReadOnlyFields=='calc'"
                        (click)="selectedReadOnlyFields='calc'">Kalkulált díjak</div>
                    <div class="calc-tab" t7e="saved-fees-title" *ngIf="isApprovedOrHigher"
                        [class.selected]="selectedReadOnlyFields=='saved'"
                        (click)="selectedReadOnlyFields='saved'">Jóváhagyott</div>
                </div>
                <app-ts-editor-readonly [ts]="ts" [field]="selectedReadOnlyFields"></app-ts-editor-readonly>
            </section>

        </ng-container>
    </ng-container>

    <section class="save-buttons">
        <button mat-raised-button type="button" [disabled]="canSave.disabled" [title]="canSave.title"
            color="primary" *ngIf="ts?.tsid" (click)="onSaveClicked()">
            <mat-icon class="mr-05">save</mat-icon>
            <span t7e="btn-save">Mentés</span>
        </button>

        <button mat-raised-button type="button" [disabled]="canSubmit.disabled" [title]="canSubmit.title"
            color="primary" *ngIf="isAtCrewMember" (click)="onSubmit()">
            <mat-icon class="mr-05">send</mat-icon>
            <span t7e="btn-submit">Beküldés</span>
            <span *ngIf="isDeptHead" t7e="to-prod-mgr"> gyártásvezetőnek</span>
            <span *ngIf="false && isProdMgr" t7e="and-approve"> és jóváhagyás</span>
        </button>

        <!-- DEPT HEAD BEKÜLDÉS SAJÁT MAGÁNAK JÓVÁHAGYÁSRA -->
        <button mat-raised-button type="button" [disabled]="canSubmit.disabled" [title]="canSubmit.title" color="primary"
            *ngIf="isAtCrewMember && isDeptHead" (click)="submitToDeptHead()">
            <mat-icon class="mr-05">send</mat-icon>
            <span t7e="btn-submit">Beküldés</span>
            <span t7e="to-dept-head"> részlegvezetőnek</span>
        </button>

        <!-- DEPT HEAD JÓVÁHAGYÁS -->
        <button mat-raised-button type="button" class="btn-success"
            *ngIf="isDeptHead && !canDeptHeadApprove(ts).disabled" (click)="approveDeptHead()">
            <mat-icon class="mr-05">check</mat-icon>
            <span t7e="btn-approve-dept">Jóváhagy (Részleg)</span>
        </button>

        <!-- GYV JÓVÁHAGYÁS -->
        <button mat-raised-button type="button" [disabled]="canSubmit.disabled" [title]="canSubmit.title"
            class="btn-success" *ngIf="isProdMgr && isWaitingForProdMgr" (click)="approve()">
            <mat-icon class="mr-05">check</mat-icon>
            <span t7e="btn-approve">Jóváhagy</span>
        </button>

        <button mat-raised-button type="button" [disabled]="canSubmit.disabled" [title]="canSubmit.title"
            class="btn-warning" *ngIf="!canSendBack(ts).disabled" (click)="sendBack()">
            <mat-icon class="mr-05">reply</mat-icon>
            <span t7e="btn-reject">Visszaküld</span>
        </button>

        <button mat-raised-button type="button" [disabled]="isSaving || isDeleted"
            [title]="isDeleted ? 'Már törölve' : ''" color="warn" *ngIf="!canDelete(ts).disabled" (click)="delete()">
            <mat-icon class="mr-05">delete</mat-icon>
            <span t7e="btn-delete">Törlés</span>
        </button>

        <button mat-icon-button (click)="showHistoryButtons = !showHistoryButtons" class="btn-show-history"
            type="button" *ngIf="ts?.tsid">
            <mat-icon class="mr-05">history</mat-icon>
        </button>

    </section>
</form>


<kendo-popover #popHistory>
    <ng-template kendoPopoverTitleTemplate>
        <div class="pop-history title" t7e="history">
            Előzmények
        </div>
    </ng-template>
    <ng-template kendoPopoverBodyTemplate>
        <div class="pop-history body">
            <app-history [tableid]="history.tableid" [id]="history.id" [colname]="history.colname"
                [propcode]="history.propcode" *ngIf="history; else noHistory"></app-history>
            <ng-template #noHistory>
                <div class="history-error" t7e="history-load-error">Előzmény hiba</div>
            </ng-template>
        </div>
    </ng-template>
</kendo-popover>


<app-save-comment *ngIf="showSaveComment" [questionText]="showSaveComment.title"
    [isCommentOptional]="showSaveComment.isCommentOptional" (saveClicked)="saveCommentReceived($event)"
    (cancelClicked)="showSaveComment = null">
</app-save-comment>